<template>
  <section class="section1 fullpage" id="section1">
    <!-- text content -->
    <div class="section1__textContentContainer" :class="locale">
      <div class="section1__textcontent" :class="locale">
        <UpperText
          :headingClass="[
            {
              'section1__textcontent__heading ar': locale == 'ar',
              'section1__textcontent__heading fr': locale == 'fr',
            },
          ]"
          uniqueClasses="section1__unique"
          :heading="$t('unicite.Slider')"
        />
        <p
          class="section1__textcontent__subtext section1__texts"
          v-if="locale == 'fr'"
        >
          {{ $t("unicite.Slider_sub.text1") }}
          <span
            class="section1__textcontent__subtext__alternateText"
            v-html=" $t('unicite.Slider_sub.text2') "
          >
          </span>
          <br />
          <span v-html="$t('unicite.Slider_sub.text3')"></span>
        </p>
        <p
          v-else
          class="section1__textcontent__subtext section1__texts"
          :class="locale + '_text'"
        >
          <span>{{ $t("unicite.Slider_sub.text1") }}</span>
          <br />
          <span v-html=" $t('unicite.Slider_sub.text2') "> </span>
          <br />
          <span v-html="$t('unicite.Slider_sub.text3')"></span>
        </p>
      </div>
    </div>

    <div class="section1__cloudSmallLeft section1__cloud-fadeout">
      <img class="img-fluid" :src="cloudSmallLeft" alt="small cloud left" />
    </div>
    <div class="section1__cloudSmallRight section1__cloud-fadeout">
      <img class="img-fluid" :src="cloudSmallRight" alt="small cloud right" />
    </div>
    <div class="section1__cloudLargeLeft">
      <img class="img-fluid" :src="cloudLargeLeft" alt="large cloud left" />
    </div>
    <div class="section1__cloudLeftLight">
      <img class="img-fluid" :src="cloudLeftLight" alt="large cloud left" />
    </div>
    <div class="section1__cloudLargeRight">
      <img class="img-fluid" :src="cloudLargeRight" alt="large cloud right" />
    </div>
    <div class="section1__logo">
      <img class="img-fluid" :src="logo" alt="logo" v-if="locale == 'fr'" />
      <img class="img-fluid" :src="logo2" alt="logo" v-else />
    </div>

    <!-- <div class="section1__mouseContainer">
      <img :src="mouse" alt="mouse-icon" />
    </div> -->
  </section>
</template>

<script>
import gsap from "gsap";
import UpperText from "@/components/Home/section1/UpperText.vue";
export default {
  components: {
    UpperText,
  },
  data() {
    return {
      cloudSmallLeft: require("../../assets/images/home/initial/small-left-cloud.webp"),
      cloudSmallRight: require("../../assets/images/home/initial/small-right-cloud.webp"),
      cloudLargeLeft: require("../../assets/images/home/initial/large-left-cloud.webp"),
      cloudLeftLight: require("../../assets/images/home/initial/left-light-cloud.webp"),
      cloudLargeRight: require("../../assets/images/home/initial/large-right-cloud.webp"),
      logo: require("../../assets/svgs/logo-lg.svg"),
      logo2: require("../../assets/images/common/logo.png"),
      mouse: require("../../assets/images/home/section1/mouse.svg"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    if (this.$i18n.locale == "ar") {
      this.runArAnimation();
    } else if (this.$i18n.locale == "fr") {
      this.runFrAnimation();
    }
  },
  methods: {
    runArAnimation() {
      //heading
      let headingDirectionInitial = window.matchMedia("(min-width: 1199px)")
        .matches
        ? { x: "-50%", opacity: 0 }
        : { y: "-350%", opacity: 0 };
      let headingDirectionFinal = window.matchMedia("(min-width: 1199px)")
        .matches
        ? { x: 0, stagger: 0.1, duration: 2, opacity: 1 }
        : { y: 0, duration: 1.6, opacity: 1 };
      //logo
      let logoInitial = window.matchMedia("(min-width: 1199px)").matches
        ? { x: "-50%", opacity: 0 }
        : { y: "-350%", opacity: 0 };
      let logoFinal = window.matchMedia("(min-width: 1199px)").matches
        ? { x: 0, stagger: 0.1, duration: 2, opacity: 1 }
        : { y: 0, duration: 1.6, opacity: 1 };
      //subtext
      let textDirectionInitial = window.matchMedia("(min-width: 1199px)")
        .matches
        ? { x: "-50%", opacity: 0 }
        : { y: "-350%", opacity: 0 };
      let textDirectionFinal = window.matchMedia("(min-width: 1199px)").matches
        ? { x: 0, stagger: 0.1, duration: 2, opacity: 1 }
        : { y: 0, duration: 1.6, opacity: 1 };

      const timeline = gsap.timeline({
        defaults: { duration: 2, ease: "Sine.easeOut" },
      });
      timeline
        .to(".section1__cloudLeftLight", { x: "-100%", duration: 4.5 })
        .to(".section1__cloudLargeRight", { x: "100%", duration: 4.5 }, 0)
        .to(".section1__cloudLargeLeft", { x: "-100%", duration: 4.5 }, 0)
        .to(".section1__cloud-fadeout", { opacity: 0, duration: 5 }, 1)
        .fromTo(
          ".section1__textcontent__heading",
          headingDirectionInitial,
          headingDirectionFinal,

          1
        )
        .fromTo(".section1__unique", logoInitial, logoFinal, 1)

        .fromTo(".section1__texts", textDirectionInitial, textDirectionFinal, 1)
        .fromTo(
          ".fixed__bottle",
          { x: "-50%", y: "120%" },
          { x: "-50%", y: "0%", duration: 2 },
          0
        )
        .to(".section1__logo", { opacity: 0, duration: 1 }, 1)
        .fromTo(
          ".section1",
          { backgroundPositionY: "0px" },
          { backgroundPositionY: "70px" },
          1
        )
        .fromTo(".animated__waves", { opacity: 0 }, { opacity: 1 }, 0.5)
        .delay(1);
    },
    runFrAnimation() {
      //   //heading
      let textContentDirectionInitial = window.matchMedia("(min-width: 1199px)")
        .matches
        ? { x: "-120%", opacity: 0.5 }
        : { y: "-120%", opacity: 0 };
      let textContentDirectionFinal = window.matchMedia("(min-width: 1199px)")
        .matches
        ? { x: 0, stagger: 0.1, duration: 3, opacity: 1 }
        : { y: 0, duration: 1.8, opacity: 1 };
      //logo
      let logoInitial = window.matchMedia("(min-width: 1199px)").matches
        ? { x: "-120%", opacity: 0.5 }
        : { y: "-120%", opacity: 0 };
      let logoFinal = window.matchMedia("(min-width: 1199px)").matches
        ? { x: 0, stagger: 0.1, duration: 3, opacity: 1 }
        : { y: 0, duration: 1.8, opacity: 1 };

      const timeline = gsap.timeline({
        defaults: { duration: 3.5, ease: "Sine.easeOut" },
      });
      timeline
        .to(".section1__cloudLeftLight", { x: "-100%", duration: 3.5 })
        .to(".section1__cloudLargeRight", { x: "100%", duration: 3.5 }, 0)
        .to(".section1__cloudLargeLeft", { x: "-100%", duration: 3.5 }, 0)
        .to(".section1__cloud-fadeout", { opacity: 0, duration: 3.5 }, 0)
        .fromTo(
          ".section1__textContentContainer",
          textContentDirectionInitial,
          textContentDirectionFinal,

          0.5
        )
        .fromTo(".section1__unique", logoInitial, logoFinal, 0.5)

        // .fromTo(".section1__texts", textDirectionInitial, textDirectionFinal, 1)
        .fromTo(
          ".fixed__bottle",
          { x: "-50%", y: "120%" },
          { x: "-50%", y: "0%", duration: 2 },
          0
        )
        .to(".section1__logo", { opacity: 0, duration: 2 }, 0.5)
        .fromTo(
          ".section1",
          { backgroundPositionY: "0px" },
          { backgroundPositionY: "70px" },
          0.5
        )
        .fromTo(".animated__waves", { opacity: 0 }, { opacity: 1 }, 0.5)
        .delay(1);
    },
  },

  //======backup

  // mounted() {
  //   //heading
  //   let headingDirectionInitial = window.matchMedia("(min-width: 1199px)")
  //     .matches
  //     ? { x: "-150%" }
  //     : { y: "-350%" };
  //   let headingDirectionFinal = window.matchMedia("(min-width: 1199px)").matches
  //     ? { x: 0, stagger: 0.1, duration: 2 }
  //     : { y: 0, duration: 1.6 };
  //   //logo
  //   let logoInitial = window.matchMedia("(min-width: 1199px)").matches
  //     ? { x: "-150%" }
  //     : { y: "-350%" };
  //   let logoFinal = window.matchMedia("(min-width: 1199px)").matches
  //     ? { x: 0, stagger: 0.1, duration: 2.2 }
  //     : { y: 0, duration: 1.6 };
  //   //subtext
  //   let textDirectionInitial = window.matchMedia("(min-width: 1199px)").matches
  //     ? { x: "-150%" }
  //     : { y: "-350%" };
  //   let textDirectionFinal = window.matchMedia("(min-width: 1199px)").matches
  //     ? { x: 0, stagger: 0.1, duration: 2 }
  //     : { y: 0, duration: 1.6 };

  //   const timeline = gsap.timeline({
  //     defaults: { duration: 1.5, ease: "Sine.easeOut" },
  //   });
  //   timeline
  //     .to(".section1__cloudLeftLight", { x: "-100%", duration: 2.5 })
  //     .to(".section1__cloudLargeRight", { x: "100%", duration: 2.5 }, 0)
  //     .to(".section1__cloudLargeLeft", { x: "-100%", duration: 2.5 }, 0)
  //     .to(".section1__cloud-fadeout", { opacity: 0, duration: 3 }, 0.5)
  //     .fromTo(
  //       ".section1__textcontent__heading",
  //       headingDirectionInitial,
  //       headingDirectionFinal,

  //       0.5
  //     )
  //     .fromTo(".section1__unique", logoInitial, logoFinal, 0.5)

  //     .fromTo(
  //       ".section1__texts",
  //       textDirectionInitial,
  //       textDirectionFinal,
  //       0.75
  //     )
  //     .fromTo(
  //       ".fixed__bottle",
  //       { x: "-50%", y: "120%" },
  //       { x: "-50%", y: "0%", duration: 2 },
  //       0
  //     )
  //     .to(".section1__logo", { opacity: 0, duration: 1 }, 0.5)
  //     .fromTo(
  //       ".section1",
  //       { backgroundPositionY: "0px" },
  //       { backgroundPositionY: "70px" },
  //       0.5
  //     )
  //     .fromTo(".animated__waves", { opacity: 0 }, { opacity: 1 }, 0.5)
  //     .delay(1);
  // }
};
</script>

<style lang="scss" scoped></style>
