<template>
  <div class="bottleContainer">
    <img :src="bottle" width="490px" alt="bottle" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // bottle: require("../../assets/images/benefits/bottle_ar.png"),
      bottle:
        this.$i18n.locale === "ar"
          ? require("../../assets/images/benefits/bottle_ar.png")
          : require("../../assets/images/home/section1/bottle.svg"),
          
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
