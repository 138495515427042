<template>
  <div>
    <AppHeader />
    <Bottle :class="['fixed__bottle', locale]" />
    <CircleMenu />
    <Waves class="animated__waves" />
    <promo-video />
    <full-page ref="fullpage" :options="options" id="fullpage">
      <Section1 class="section" />
      <Section2
        class="section "
        @onClick="showPopup"
        :showModal="showModal"
        v-bind:value.sync="showModal"
      />

      <Section3 class="section" />
      <Section4 class="section" />
      <Section5 class="section" />
      <Section6 class="section " />
      <div
        v-if="showsection6content && locale == 'fr'"
        class="section section6__lowerbg"
      ></div>
      <div
        v-else-if="showsection6content && locale == 'ar'"
        class="section section6__lowerbg ar"
      ></div>
      <AppFooter
        @onClick="moveup"
        class="section fp-auto-height footer-section"
        :class="locale"
      />
    </full-page>
    <!-- :class="{ 'fp-auto-height': showsection6content }" -->
    <VideoModal
      :modalbg="modalbg"
      videoclass="section2__videoModal"
      @onClose="showModal = false"
      :showModal="showModal"
      v-bind:value.sync="showModal"
    >
      <template #video>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/KfaU0IoiBN0?controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </VideoModal>
  </div>
</template>

<script>
import Section1 from "@/components/Home/section1.vue";
import Section2 from "@/components/Home/section2.vue";
import Section3 from "@/components/Home/section3.vue";
import Section4 from "@/components/Home/section4.vue";
import Section5 from "@/components/Home/section5.vue";
import Section6 from "@/components/Home/section6.vue";
import Bottle from "@/components/general/Bottle.vue";
import Waves from "@/components/general/Waves.vue";
import CircleMenu from "@/components/general/Circles.vue";
import AppHeader from "@/components/Header.vue";
import AppFooter from "../components/footer/Footer.vue";
import VideoModal from "@/components/common/VideoModal";
import PromoVideo from "../components/PromoVideo.vue";
export default {
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Bottle,
    CircleMenu,
    AppHeader,
    Waves,
    AppFooter,
    VideoModal,
    PromoVideo
  },
  data() {
    return {
      modalbg: require("../assets/images/home/section2/video-bg-wide.webp"),
      showModal: false,
      options: {
        licenseKey: "BD497841-800F4E51-B6A2DCEB-DE65B139",
        scrollingSpeed: 1000,
        scrollBar: true,
        responsiveWidth: 991
      }
    };
  },
  mounted() {
    if (this.$route.hash) {
      this.$scrollTo(this.$route.hash, 500);
    }
  },
  methods: {
    showPopup() {
      this.showModal = true;
    },
    moveup() {
      this.$scrollTo("#section1", 500);
    },
    onClickOutside(e) {
      let s2videobtn = document.querySelector("#videoSectionBtn");
      if (
        !s2videobtn.contains(e.target) &&
        this.showModal &&
        e.target !== s2videobtn
      ) {
        this.showModal = false;
      }
    }
  },
  computed: {
    showsection6content() {
      return window.matchMedia("(max-width: 991px)").matches;
    },
    locale() {
      return this.$i18n.locale;
    }
  }
};
</script>

<style lang="scss" scoped></style>
