<template>
  <section class="section6 position-relative fullpage" id="uniciteSection6">
    <div class="section6__left " :class="locale"></div>
    <div
      class="section6__right"
      data-aos="s6bg-pos-left"
      data-aos-delay="100"
      data-aos-anchor-placement="top-center"
    >
      <TextContent @onClick="TextModal = true" />

      <div class="section6__mobileBottles d-lg-none d-block">
        <div class="white-bottle ">
          <img :src="whitBottle" alt="" />
        </div>
        <div class="section6__bottle ">
          <img :src="bottle" alt="" />
        </div>
      </div>
    </div>

    <TextModalComp
      :modalboxClass="`section6__Modal ${locale}`"
      :mainHeadingClass="`section6__Modal__mainHeading ${locale}`"
      :mainHeading="$t('unicite.Section_5_modal.mainHeading')"
      :headingtext1="$t('unicite.Section_5_modal.heading1')"
      :headingtext2="$t('unicite.Section_5_modal.heading2')"
      :headingtext3="$t('unicite.Section_5_modal.heading3')"
      :text1="$t('unicite.Section_5_modal.text1')"
      :text2="$t('unicite.Section_5_modal.text2')"
      :headingClass="`section6__Modal__heading ${locale}`"
      alternateHeadingClass=""
      :textClass="`section6__Modal__text d-block py-1 ${locale}`"
      :showModal="TextModal"
      :modalBoxContentClass="`section6__Modal__content ${locale}`"
      v-bind:value.sync="TextModal"
      :unique="locale == 'fr' ? unique : unique2"
      :cross="cross"
      :logo="logo"
      :mobileModal="mobileModal"
      :uniqueImgClass="`section6__Modal__img ${locale} my-2`"
    >
      <template v-slot:modalbg>
        <img :src="modalBackground" alt="" />
      </template>
    </TextModalComp>

    <div class="white-bottle d-lg-block d-none">
      <img :src="whitBottle" alt="" />
    </div>
    <div class="section6__bottle d-lg-block d-none">
      <img :src="bottle" width="490px" alt="" />
    </div>
  </section>
</template>

<script>
import TextContent from "@/components/Home/section6/TextContent.vue";
import TextModalComp from "@/components/general/ModalWhite.vue";

export default {
  components: {
    TextContent,
    TextModalComp,
  },
  data() {
    return {
      mobileModal: {},
      whitBottle: require("../../assets/images/home/section6/white-bottle.webp"),
      bottle: this.$i18n.locale === "ar"
          ? require("../../assets/images/benefits/bottle_ar.png")
          : require("../../assets/images/home/section1/bottle.svg"),
      TextModal: false,
      modalBackground: require("../../assets/images/home/section6/modalbg.webp"),
      unique: require("../../assets/images/home/section6/unique.webp"),
      unique2: require("../../assets/images/common/section6_logo.png"),
      cross: require("../../assets/images/home/section6/cross.webp"),
      logo: require("../../assets/images/home/section6/logo.webp"),
    };
  },
  mounted() {
    this.mobileModal = {
      mobileModalClass: `section6__textModal__mobile  ${this.$i18n.locale}`,
      mobileModalHeadingClass: `section6__textModal__mobile__heading ${this.$i18n.locale}`,
      uniqueClass: `section6__textModal__mobile__unique  ${this.$i18n.locale}`,
      mobileModalTextClass: `section6__textModal__mobile__text  ${this.$i18n.locale}`,
      mobileModalSubtextClass: `section6__textModal__mobile__subtext ${this.$i18n.locale}`,
      unique:
        this.$i18n.locale == "fr"
          ? require("../../assets/images/home/section6/unique.webp")
          : require("../../assets/images/common/section6_logo.png"),
      heading: this.$i18n.t("unicite.Section_5_mobile_modal.heading"),
      subtext: this.$i18n.t("unicite.Section_5_mobile_modal.subtext"),
      text: this.$i18n.t("unicite.Section_5_mobile_modal.text"),
      cross: require("../../assets/images/home/section6/cross.webp"),
    };
  },
  watch: {
    "$i18n.locale"(value) {
      this.mobileModal = {
        mobileModalClass: `section6__textModal__mobile  ${value}`,
        mobileModalHeadingClass: `section6__textModal__mobile__heading ${value}`,
        uniqueClass: `section6__textModal__mobile__unique  ${value}`,
        mobileModalTextClass: `section6__textModal__mobile__text  ${value}`,
        mobileModalSubtextClass: `section6__textModal__mobile__subtext ${value}`,
        unique:
          value == "fr"
            ? require("../../assets/images/home/section6/unique.webp")
            : require("../../assets/images/common/section6_logo.png"),
        heading: this.$i18n.t("unicite.Section_5_mobile_modal.heading"),
        subtext: this.$i18n.t("unicite.Section_5_mobile_modal.subtext"),
        text: this.$i18n.t("unicite.Section_5_mobile_modal.text"),
        cross: require("../../assets/images/home/section6/cross.webp"),
      };
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },

  methods: {
    // visibilityChanged(isVisible) {
    //   if (isVisible) {
    //     gsap.to(".fixed__bottle", { opacity: 0, duration: 1.5 });
    //     gsap.fromTo(
    //       ".section6__bottle",
    //       { y: "100%" },
    //       { y: "0%", duration: 1 }
    //     );
    //   } else {
    //     gsap.to(".fixed__bottle", { opacity: 1, duration: 1 });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped></style>
