<template>
  <div>
    <div
      class="modalbox desktopModal"
      :class="[{ show: showModal }, modalboxClass]"
      v-click-outside="onClickOutside"
    >
      <div class="modalbox__body" :class="[locale, modalbodyclass]">
        <slot name="modalbg"></slot>
        <button
          class="modalbox__cross whiteModalcross btn"
          :class="locale"
          @click="$emit('update:value', false)"
        >
          <img :src="cross" alt="" />
        </button>
        <div class="modalbox__body__content" :class="modalBoxContentClass">
          <div class="modalbox__main__heading">
            <h1 class="text-uppercase" :class="mainHeadingClass">
              {{ mainHeading }}
            </h1>
            <img
              class="img-fluid"
              :class="uniqueImgClass"
              :src="unique"
              alt="unique"
            />
          </div>
          <h1 :class="headingClass">
            <span v-html="headingtext1"></span>
            <span v-html="headingtext2" :class="alternateHeadingClass"></span>
            <span v-html="headingtext3"></span>
          </h1>
          <span v-html="text1" :class="textClass"></span>
          <span v-html="text2" :class="textClass"></span>
        </div>
        <div class="modalbox__body__logo">
          <img :src="logo" alt="" />
        </div>
      </div>
    </div>
    <!-- mobile modal -->
    <div :class="[{ show: showModal }, mobileModal.mobileModalClass]">
      <div class="position-relative">
        <button
          class="btn textModal__mobile__cross"
          @click="$emit('update:value', false)"
        >
          <img :src="mobileModal.cross" alt="" />
        </button>

        <h1
          class="text-center text-uppercase"
          :class="mobileModal.mobileModalHeadingClass"
          v-html="mobileModal.heading"
        ></h1>
        <div class="text-center">
          <img
            class="img-fluid"
            :class="mobileModal.uniqueClass"
            v-if="mobileModal.unique"
            :src="mobileModal.unique"
            alt=""
          />
        </div>
        <p
          class="text-center"
          v-if="mobileModal.subtext"
          :class="mobileModal.mobileModalSubtextClass"
          v-html="mobileModal.subtext"
        ></p>
        <p
          class="text-center"
          :class="mobileModal.mobileModalTextClass"
          v-html="mobileModal.text"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: [
    "mobileModal",
    "headingtext1",
    "headingtext2",
    "headingtext3",
    "mainHeading",
    "mainHeadingClass",
    "alternateHeadingClass",
    "text1",
    "text2",
    "headingClass",
    "textClass",
    "showModal",
    "modalboxClass",
    "modalBoxContentClass",
    "unique",
    "cross",
    "logo",
    "modalbodyclass",
    "uniqueImgClass",
  ],
  methods: {
    onClickOutside(e) {
      let section4btn = document.querySelector(".section4__btn");
      let section6btn = document.querySelector(".section6__textcontent__btn");

      if (
        !section4btn.contains(e.target) &&
        this.showModal &&
        e.target !== section4btn &&
        !section6btn.contains(e.target) &&
        this.showModal &&
        e.target !== section6btn
      ) {
        this.$emit("update:value", false);
      }
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
