<template>
  <section id="uniciteSection2">
    <SectionContent
      sectionClass="section2"
      :videosubtext="$t('unicite.Button_vid')"
      :videoSectionBg="videoSectionBg"
      :modalbg="modalbg"
      :textContentbg="textContentbg"
      :textContentHeading="$t('unicite.Section_1_title')"
      headingClass="commonsection__textContent__heading"
      :textClass="['commonsection__textContent__text ', locale]"
      :text="$t('unicite.Section_1_desc_short')"
      :modalContent="modalContent"
      :btnText="$t('unicite.button_more')"
      btnClass="section2__textcontent__button"
      plusIconColor="red"
      btnTextClass="commonsection__textContent__btn__text"
      :textContentClass="['commonsection__righttext', locale]"
      :textContentMainClass="[
        'commonsection__textContent section2__commonsection__textcontent',
        locale,
      ]"
      videobtnClass="section2__videobtn"
      :mobileModal="mobileModal"
      @onVideobtnClick="$emit('onClick')"
      :uniqueImgAr="uniqueImgAr"
    />
  </section>
</template>

<script>
import vClickOutside from "v-click-outside";
import SectionContent from "./CommonSection.vue";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  // props: ["showModal"],
  data() {
    return {
      uniqueImgAr: require("../../assets/images/common/unique_modal_ar.png"),
      videoSectionBg: require("../../assets/images/home/section2/video-bg.webp"),
      modalbg: require("../../assets/images/home/section2/video-bg-wide.webp"),
      textContentbg: require("../../assets/images/home/common/section3-bg-right.webp"),
      textContentText:
        "Une eau unique, comme chaque <br> goutte d’eau qui la compose. Venue du ciel sous <br> forme de pluie ou de neige, chaque goutte de <br> l’eau minérale naturelle Sidi Harazem parcourt <br> le même chemin ancestral",
      modalContent: {},
      mobileModal: {},
    };
  },
  components: {
    SectionContent,
  },
  mounted() {
    this.modalContent = {
      heading1: this.$i18n.t("unicite.section1_modal.heading1"),
      heading2: this.$i18n.t("unicite.section1_modal.heading2"),
      heading3: this.$i18n.t("unicite.section1_modal.heading3"),

      text1: this.$i18n.t("unicite.section1_modal.text1"),
      text2: this.$i18n.t("unicite.section1_modal.text2"),
    };
    this.mobileModal = {
      alternatePositionClass: "d-flex  flex-column-reverse",
      mobileModalClass: `section2__textModal__mobile ${this.$i18n.locale}`,
      mobileModalHeadingClass: `section2__textModal__mobile__heading ${this.$i18n.locale}`,
      unique: require("../../assets/images/home/section1/unique.webp"),
      uniqueAr: require("../../assets/images/common/unique_modal_ar.png"),
      uniqueClass: `section2__textModal__mobile__unique ${this.$i18n.locale}`,
      mobileModalTextClass: `section2__textModal__mobile__text ${this.$i18n.locale}`,
      heading: this.$i18n.t("unicite.section1_mobile_modal.heading"),
      cross: require("../../assets/images/home/common/cross-blue.webp"),

      text: this.$i18n.t("unicite.section1_mobile_modal.text"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    "$i18n.locale"(value) {
      this.modalContent = {
        heading1: this.$i18n.t("unicite.section1_modal.heading1"),
        heading2: this.$i18n.t("unicite.section1_modal.heading2"),
        heading3: this.$i18n.t("unicite.section1_modal.heading3"),

        text1: this.$i18n.t("unicite.section1_modal.text1"),
        text2: this.$i18n.t("unicite.section1_modal.text2"),
      };
      this.mobileModal = {
        alternatePositionClass: "d-flex  flex-column-reverse",
        mobileModalClass: `section2__textModal__mobile ${value}`,
        mobileModalHeadingClass: `section2__textModal__mobile__heading ${value}`,
        unique: require("../../assets/images/home/section1/unique.webp"),
        uniqueAr: require("../../assets/images/common/unique_modal_ar.png"),
        uniqueClass: `section2__textModal__mobile__unique ${value}`,
        mobileModalTextClass: `section2__textModal__mobile__text ${value}`,
        heading: this.$i18n.t("unicite.section1_mobile_modal.heading"),
        cross: require("../../assets/images/home/common/cross-blue.webp"),

        text: this.$i18n.t("unicite.section1_mobile_modal.text"),
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
