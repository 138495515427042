<template>
  <div>
    <!-- desktop Modal -->
    <div
      v-click-outside="onClickOutside"
      class="modalbox"
      :class="[{ show: showModal }, modalboxClass]"
    >
      <div class="modalbox__body">
        <ModalBg :ModalsvgClass="ModalsvgClass" />
        <button
          class="modalbox__cross btn"
          :class="modalcrossClass"
          @click="$emit('update:value', false)"
        >
          <img :src="cross" alt="" />
        </button>
        <div class="modalbox__body__content" :class="locale">
          <h1 :class="headingClass">
            <span v-if="headingtext1" v-html="headingtext1"></span>
            <span
              v-if="headingtext2"
              v-html="headingtext2"
              :class="alternateHeadingClass"
            ></span>
            <div v-if="desktopimage">
              <img
                :class="`modal__img ${locale}`"
                class="img-fluid"
                :src="desktopimage"
                alt=""
              />
            </div>
            <span
              v-if="headingtext3"
              v-html="headingtext3"
              :class="alternateHeadingClass2"
            ></span>
          </h1>

          <span v-html="text1" :class="textClass"></span>
          <span v-html="text2" :class="textClass"></span>
        </div>
        <Logo />
      </div>
    </div>
    <!-- mobile modal -->

    <div :class="[{ show: showModal }, mobileModal.mobileModalClass]">
      <div class="position-relative">
        <button
          class="btn textModal__mobile__cross mr-3"
          @click="$emit('update:value', false)"
        >
          <img :src="mobileModal.cross" alt="" />
        </button>

        <div class="modalbox__body__content" :class="locale">
          <h1 :class="headingClass">
            <span v-if="headingtext1" v-html="headingtext1"></span>
            <span
              v-if="headingtext2"
              v-html="headingtext2"
              :class="alternateHeadingClass"
            ></span>
            <div v-if="desktopimage">
              <img class="img-fluid" :src="desktopimage" alt="" />
            </div>
            <span
              v-if="headingtext3"
              v-html="headingtext3"
              :class="alternateHeadingClass2"
            ></span>
          </h1>

          <span v-html="text1" :class="textClass"></span>
          <span v-html="text2" :class="textClass"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/svgs/Logo.vue";
import ModalBg from "@/components/svgs/ModalBg.vue";
import vClickOutside from "v-click-outside";
export default {
  props: [
    "modalcrossClass",
    "ModalsvgClass",
    "mobileModal",
    "desktopimage",
    "headingtext1",
    "headingtext2",
    "headingtext3",

    "alternateHeadingClass",
    "text1",
    "text2",
    "headingClass",
    "textClass",
    "showModal",
    "modalboxClass",
    "alternateHeadingClass2",
  ],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside(e) {
      let section2btn = document.querySelector(
        ".section2__textcontent__button"
      );
      let section3btn = document.querySelector(".section3__btn");
      let section5btn = document.querySelector(".section5__btn");

      if (
        !section2btn.contains(e.target) &&
        this.showModal &&
        e.target !== section2btn &&
        !section3btn.contains(e.target) &&
        this.showModal &&
        e.target !== section3btn &&
        !section5btn.contains(e.target) &&
        this.showModal &&
        e.target !== section5btn
      ) {
        this.$emit("update:value", false);
      }
    },
  },
  data() {
    return {
      cross: require("../../../assets/images/home/common/cross.webp"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },

  components: {
    Logo,
    ModalBg,
  },
};
</script>

<style lang="scss" scoped></style>
