<template>
  <div class="lowerContent">
    <span class="d-block" :class="textClass" v-html="text"></span>
    <Button
      :btnTextClass="btnTextClass"
      :plusIconColor="plusIconColor"
      :btnClass="btnClass"
      :btnText="btnText"
      @onClick="$emit('onClick')"
      :btnimgsrc="btnimgsrc"
    />
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  props: [
    "btnimgsrc",
    "text",
    "textClass",
    "btnTextClass",
    "btnText",
    "plusIconColor",
    "btnClass",
  ],
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped></style>
