<template>
  <section class="section4 " id="uniciteSection4">
    <div
      class="section4__left position-relative  "
      data-aos="bg-pos-right"
      data-aos-delay="100"
      data-aos-anchor-placement="top-center"
      :class="locale"
    >
      <div v-if="showDesktop">
        <TextContent
          :heading="$t('unicite.Section_3_title')"
          :headingClass="`section4__textContent__heading ${locale}`"
          :textClass="
            `commonsection__textContent__text section4__text ${locale}`
          "
          :text="$t('unicite.Section_3_desc_short')"
          btnText="Lire la suite"
          btnClass="section4__btn"
          plusIconColor="red"
          btnTextClass="`section4__textContent__btn__text ${locale}`"
          :textContentClass="`section4__textContent ${locale}`"
          @onClick="TextModal = true"
        />
      </div>
    </div>

    <div
      class="section4__right"
      data-aos="s4bg-pos-left"
      data-aos-delay="100"
      data-aos-anchor-placement="top-center"
    >
      <div
        class="section4__mobileContent position-relative  "
        data-aos="bg-pos-right"
        data-aos-delay="100"
        data-aos-anchor-placement="top-center"
      >
        <TextContent
          :heading="$t('unicite.Section_3_title')"
          :headingClass="`section4__textContent__heading ${locale}`"
          :textClass="
            `commonsection__textContent__text section4__text ${locale}`
          "
          :text="$t('unicite.Section_3_desc_short')"
          btnText="Lire la suite"
          btnClass="section4__btn"
          plusIconColor="red"
          btnTextClass="section4__textContent__btn__text"
          :textContentClass="`section4__textContent ${locale}`"
          @onClick="TextModal = true"
        />
      </div>
    </div>
    <TextModalComp
      modalboxClass="section4__Modal"
      modalbodyclass="section4__modalbox__body"
      :uniqueImgClass="`section4__Modal__unique ${locale}`"
      :mainHeadingClass="`section4__Modal__mainHeading ${locale}`"
      :mainHeading="$t('unicite.Section_3_modal.mainHeading')"
      :headingtext1="$t('unicite.Section_3_modal.heading1')"
      :headingtext2="$t('unicite.Section_3_modal.heading2')"
      :headingtext3="$t('unicite.Section_3_modal.heading3')"
      :text1="$t('unicite.Section_3_modal.text1')"
      :text2="$t('unicite.Section_3_modal.text2')"
      :headingClass="`section4__Modal__heading ${locale}`"
      alternateHeadingClass="section4__Modal__heading__alternate"
      :textClass="`section4__Modal__text d-block py-2 ${locale}`"
      :showModal="TextModal"
      v-bind:value.sync="TextModal"
      modalBoxContentClass="section4__Modal__content"
      :unique="locale == 'fr' ? unique : unique2"
      :cross="cross"
      :logo="logo"
      :mobileModal="mobileModal"
    >
      <template v-slot:modalbg>
        <img :src="modalBackground" alt="" />
      </template>
    </TextModalComp>
  </section>
</template>

<script>
import TextContent from "@/components/common/TextSection";
import TextModalComp from "@/components/general/ModalWhite.vue";
export default {
  mounted() {
    if (window.matchMedia("(max-width: 991px)").matches) {
      this.showDesktop = false;
    } else {
      this.showDesktop = true;
    }

    this.mobileModal = {
      mobileModalClass: `section4__textModal__mobile ${this.$i18n.locale}`,
      mobileModalHeadingClass: `section4__textModal__mobile__heading ${this.$i18n.locale}`,
      unique:
        this.$i18n.locale == "fr"
          ? require("../../assets/images/home/section1/unique.webp")
          : require("../../assets/images/common/section1_logo.png"),
      uniqueClass: `section4__textModal__mobile__unique ${this.$i18n.locale}`,
      mobileModalTextClass: `section4__textModal__mobile__text ${this.$i18n.locale}`,
      cross: require("../../assets/images/home/common/cross-blue.webp"),

      heading: this.$i18n.t("unicite.Section_3_mobile_modal.heading"),
      text: this.$i18n.t("unicite.Section_3_mobile_modal.text"),
    };
  },
  watch: {
    "$i18n.locale"(value) {
      this.mobileModal = {
        mobileModalClass: `section4__textModal__mobile ${value}`,
        mobileModalHeadingClass: `section4__textModal__mobile__heading ${value}`,
        unique:
          value == "fr"
            ? require("../../assets/images/home/section1/unique.webp")
            : require("../../assets/images/common/section1_logo.png"),
        uniqueClass: `section4__textModal__mobile__unique ${value}`,
        mobileModalTextClass: `section4__textModal__mobile__text ${value}`,
        cross: require("../../assets/images/home/common/cross-blue.webp"),

        heading: this.$i18n.t("unicite.Section_3_mobile_modal.heading"),
        text: this.$i18n.t("unicite.Section_3_mobile_modal.text"),
      };
    },
  },
  data() {
    return {
      mobileModal: {},
      showDesktop: false,
      modalBackground: require("../../assets/images/home/common/modalbg-white.webp"),
      unique: require("../../assets/images/home/section4/unique.webp"),
      unique2: require("../../assets/images/common/section1_logo.png"),
      cross: require("../../assets/images/home/common/cross-blue.webp"),
      logo: require("../../assets/images/home/common/logo-blue.webp"),
      TextModal: false,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  props: [
    "textContentHeading",
    "headingClass",
    "text",
    "textClass",
    "btnText",
    "btnClass",
    "plusIconColor",
    "btnTextClass",
    "textContentClass",
    "textContentbg",
  ],
  components: {
    TextContent,
    TextModalComp,
  },
};
</script>

<style lang="scss" scoped></style>
