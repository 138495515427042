<template>
  <section class="section5" id="uniciteSection5">
    <div class="section5__left"></div>
    <div
      class="section5__right position-relative commonsection__textContent "
      data-aos="bg-pos-left"
      data-aos-delay="100"
      data-aos-anchor-placement="top-center"
    >
      <TextContent
        :heading="$t('unicite.Section_4_title')"
        :headingClass="`section5__textContent__heading ${locale}`"
        :textClass="
          `commonsection__textContent__text section5__textContent__text ${locale}`
        "
        :text="$t('unicite.Section_4_desc_short')"
        :btnText="$t('unicite.button_more')"
        :btnClass="`section5__btn ${locale}`"
        plusIconColor="red"
        :btnTextClass="`commonsection__textContent__btn__text ${locale}`"
        :textContentClass="
          `commonsection__righttext section5__textContent ${locale}`
        "
        alternateImg="true"
        @onClick="TextModal = true"
        :subtext="$t('unicite.Section_4_subtitle')"
        :subtextclass="`section5__textContent__subtext ${locale}`"
      />
      <TextModalComp
        v-if="locale == 'fr'"
        :modalboxClass="`section5__textModal ${locale}`"
        :headingtext1="$t('unicite.Section_4_modal.heading1')"
        :headingtext2="$t('unicite.Section_4_modal.heading2')"
        :headingtext3="$t('unicite.Section_4_modal.heading3')"
        :text1="$t('unicite.Section_4_modal.text1')"
        :text2="$t('unicite.Section_4_modal.text2')"
        :headingClass="
          `modalbox__modalHeading section5__textModal__heading ${locale}`
        "
        :alternateHeadingClass="`modalbox__modalHeading__alternate ${locale}`"
        :alternateHeadingClass2="`modalbox__modalHeading__alternate2 ${locale}`"
        :textClass="
          `modalbox__modalText d-block py-1 section5__textModal__text ${locale}`
        "
        :showModal="TextModal"
        v-bind:value.sync="TextModal"
        :mobileModal="mobileModal"
        :desktopimage="locale == 'fr' ? desktopimage1 : desktopimage2"
        :ModalsvgClass="`section5__textModal__svg ${locale}`"
        :modalcrossClass="`section5__textModal__cross ${locale}`"
      />
      <TextModalCompAr
        v-else
        :modalboxClass="`section5__textModal ${locale}`"
        :headingtext1="$t('unicite.Section_4_modal.heading1')"
        :headingtext2="$t('unicite.Section_4_modal.heading2')"
        :headingtext3="$t('unicite.Section_4_modal.heading3')"
        :text1="$t('unicite.Section_4_modal.text1')"
        :text2="$t('unicite.Section_4_modal.text2')"
        :headingClass="
          `modalbox__modalHeading section5__textModal__heading ${locale}`
        "
        :alternateHeadingClass="`modalbox__modalHeading__alternate ${locale}`"
        :alternateHeadingClass2="`modalbox__modalHeading__alternate2 ${locale}`"
        :textClass="
          `modalbox__modalText d-block py-1 section5__textModal__text ${locale}`
        "
        :showModal="TextModal"
        v-bind:value.sync="TextModal"
        :mobileModal="mobileModal"
        :desktopimage="desktopimage2"
        :ModalsvgClass="`section5__textModal__svg ${locale}`"
        :modalcrossClass="`section5__textModal__cross ${locale}`"
      />
    </div>
  </section>
</template>

<script>
import TextContent from "@/components/Home/section5/TextContent.vue";
import TextModalComp from "@/components/general/Modal.vue";
import TextModalCompAr from "@/components/Home/section5/Modal.vue";
export default {
  components: {
    TextContent,
    TextModalComp,
    TextModalCompAr,
  },
  data() {
    return {
      mobileModal: {},
      TextModal: false,

      modalbg: require("../../assets/images/home/section5/modal-bg.webp"),
      desktopimage1: require("../../assets/images/home/section5/lunique.png"),
      desktopimage2: require("../../assets/images/common/unique_modal_ar.png"),
      textContentbg: require("../../assets/images/home/common/section3-bg-right.webp"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    "$i18n.locale"(value) {
      this.mobileModal = {
        alternatePositionClass: `d-flex flex-column column-reverse ${value}`,
        mobileModalClass: `section5__textModal__mobile  ${value}`,
        mobileModalHeadingClass: `section5__textModal__mobile__heading ${value}`,
        unique: require("../../assets/images/home/section5/unique.webp"),
        uniqueClass: `section5__textModal__mobile__unique  ${value}`,
        mobileModalTextClass: `section5__textModal__mobile__text  ${value}`,
        heading: this.$i18n.t("unicite.Section_4_mobile_modal.heading"),
        cross: require("../../assets/images/home/common/cross-blue.webp"),

        text: this.$i18n.t("unicite.Section_4_mobile_modal.text"),
      };
    },
  },
  mounted() {
    this.mobileModal = {
      alternatePositionClass: `d-flex flex-column column-reverse ${this.$i18n.locale}`,
      mobileModalClass: `section5__textModal__mobile  ${this.$i18n.locale}`,
      mobileModalHeadingClass: `section5__textModal__mobile__heading ${this.$i18n.locale}`,
      unique: require("../../assets/images/home/section5/unique.webp"),
      uniqueClass: `section5__textModal__mobile__unique  ${this.$i18n.locale}`,
      mobileModalTextClass: `section5__textModal__mobile__text  ${this.$i18n.locale}`,
      heading: this.$i18n.t("unicite.Section_4_mobile_modal.heading"),
      cross: require("../../assets/images/home/common/cross-blue.webp"),

      text: this.$i18n.t("unicite.Section_4_mobile_modal.text"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
