<template>
  <div
    data-aos="fade-up"
    data-aos-delay="100"
    data-aos-anchor-placement="top-center"
  >
    <div class=" section6__textcontent" :class="locale">
      <h1
        class="section6__textcontent__heading text-uppercase "
        :class="locale"
      >
        {{ $t("unicite.Section_5_title") }}
      </h1>
      <img
        v-if="locale == 'fr'"
        class="section6__textcontent__unique"
        :src="unique"
        alt="unique image text"
      />
      <img
        v-else
        class="section6__textcontent__unique"
        :src="unique2"
        alt="unique image text"
      />

      <LowerContent
        v-if="locale == 'fr'"
        :textClass="`section6__textcontent__text ${locale}`"
        :text="$t('unicite.Section_5_text')"
        @onClick="$emit('onClick')"
        :btnTextClass="`commonsection__textContent__btn__text ${locale}`"
        plusIconColor="white"
        :btnClass="`section6__textcontent__btn ${locale}`"
        :btnText="$t('unicite.button_more')"
        :btnimgsrc="btnImg"
      />
      <LowerContentAR
        v-else
        :textClass="`section6__textcontent__text ${locale}`"
        :text="$t('unicite.Section_5_text')"
        @onClick="$emit('onClick')"
        :btnTextClass="`commonsection__textContent__btn__text ${locale}`"
        plusIconColor="white"
        :btnClass="`section6__textcontent__btn ${locale}`"
        :btnText="$t('unicite.button_more')"
      />
    </div>
  </div>
</template>

<script>
import LowerContent from "@/components/general/LowerContent.vue";
import LowerContentAR from "@/components/Home/section6/LowerContentAr.vue";
export default {
  components: {
    LowerContent,
    LowerContentAR,
  },
  props: ["headingClass", "heading"],
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },

  data() {
    return {
      btnImg: require("../../../assets/images/home/common/s6btn.svg"),

      unique: require("../../../assets/images/home/section6/unique.webp"),
      unique2: require("../../../assets/images/common/section6_logo.png"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
