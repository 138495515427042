<template>
  <div>
    <h1
      :class="headingClass"
      class="text-primary text-uppercase"
      v-html="heading"
    ></h1>
    <img
      class="unique__img "
      :src="unique2"
      :class="uniqueClasses"
      alt="unique image text"
    />
    <p v-if="subtext" :class="subtextclass">{{ subtext }}</p>
  </div>
</template>

<script>
export default {
  props: [
    "headingClass",
    "heading",

    "uniqueClasses",
    "subtextclass",
    "subtext",
  ],
  data() {
    return {
      unique2: "",
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.unique2 = require("../../../assets/images/common/section3_logo.png");
  },
  watch: {
    "$i18n.locale"(value) {
      this.unique2 = require("../../../assets/images/common/section3_logo.png");
    },
  },
};
</script>

<style lang="scss" scoped></style>
