<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="123"
    height="82.002"
    viewBox="0 0 123 82.002"
  >
    <g
      id="Union_1"
      data-name="Union 1"
      transform="translate(-6976.999 -22141)"
      :fill="bg || '#2f63a9'"
    >
      <path
        d="M 7069.00048828125 22221.001953125 L 6996 22221.001953125 C 6991.45751953125 22221.001953125 6987.18798828125 22219.234375 6983.9775390625 22216.0234375 C 6980.7666015625 22212.8125 6978.99853515625 22208.54296875 6978.99853515625 22204 L 6978.99853515625 22160.001953125 C 6978.99853515625 22155.458984375 6980.7666015625 22151.189453125 6983.97705078125 22147.978515625 C 6987.1875 22144.767578125 6991.45751953125 22143 6996 22143 L 7069.00048828125 22143 C 7073.541015625 22143 7077.81005859375 22144.767578125 7081.02099609375 22147.978515625 C 7084.23291015625 22151.189453125 7086.001953125 22155.458984375 7086.001953125 22160.001953125 L 7086.001953125 22169.3671875 L 7086.001953125 22170.166015625 L 7086.5517578125 22170.744140625 L 7097.24072265625 22181.998046875 L 7086.5517578125 22193.251953125 L 7086.001953125 22193.830078125 L 7086.001953125 22194.62890625 L 7086.001953125 22204 C 7086.001953125 22208.541015625 7084.23291015625 22212.810546875 7081.02099609375 22216.0234375 C 7077.8095703125 22219.234375 7073.54052734375 22221.001953125 7069.00048828125 22221.001953125 Z"
        stroke="none"
      />
      <path
        d="M 7069.00048828125 22219.001953125 C 7073.00634765625 22219.001953125 7076.7734375 22217.44140625 7079.60693359375 22214.607421875 C 7082.44091796875 22211.775390625 7084.001953125 22208.0078125 7084.001953125 22204 L 7084.001953125 22193.03125 L 7085.1015625 22191.875 L 7094.482421875 22181.998046875 L 7084.001953125 22170.96484375 L 7084.001953125 22160.001953125 C 7084.001953125 22155.994140625 7082.44140625 22152.2265625 7079.60693359375 22149.392578125 C 7076.7734375 22146.560546875 7073.0068359375 22145 7069.00048828125 22145 L 6996 22145 C 6991.99169921875 22145 6988.22412109375 22146.560546875 6985.3916015625 22149.392578125 C 6982.55859375 22152.224609375 6980.99853515625 22155.994140625 6980.99853515625 22160.001953125 L 6980.99853515625 22204 C 6980.99853515625 22208.0078125 6982.55859375 22211.775390625 6985.3916015625 22214.609375 C 6988.224609375 22217.44140625 6991.9921875 22219.001953125 6996 22219.001953125 L 7069.00048828125 22219.001953125 M 7069.00048828125 22223.001953125 L 6996 22223.001953125 C 6985.50341796875 22223.001953125 6976.99853515625 22214.49609375 6976.99853515625 22204 L 6976.99853515625 22160.001953125 C 6976.99853515625 22149.50390625 6985.50341796875 22141 6996 22141 L 7069.00048828125 22141 C 7079.4912109375 22141 7088.001953125 22149.50390625 7088.001953125 22160.001953125 L 7088.001953125 22169.3671875 L 7099.9990234375 22181.998046875 L 7088.001953125 22194.62890625 L 7088.001953125 22204 C 7088.001953125 22214.49609375 7079.4912109375 22223.001953125 7069.00048828125 22223.001953125 Z"
        stroke="none"
        fill="#f7f7f7"
      />
    </g>
  </svg>
</template>

<script>
export default { props: ["bg"] };
</script>

<style lang="scss" scoped></style>
