<template>
  <div>
    <div v-if="alternateImg">
      <div v-if="locale == 'fr'">
        <img class="lunique__img" :src="unique2" alt="unique image text" />
        <h1
          :class="headingClass"
          class="text-primary text-uppercase"
          v-html="heading"
        ></h1>
      </div>
      <div v-if="locale == 'ar'" class="mb-2">
        <h1
          :class="headingClass"
          class="text-primary text-uppercase"
          v-html="heading"
        ></h1>
        <img class="lunique__img mt-0" :src="unique2" alt="unique image text" />
      </div>
    </div>
    <div v-else>
      <h1
        :class="headingClass"
        class="text-primary text-uppercase"
        v-html="heading"
      ></h1>
      <img
        class="unique__img "
        :src="unique"
        :class="uniqueClasses"
        alt="unique image text"
      />
      <p v-if="subtext" :class="subtextclass">{{ subtext }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "headingClass",
    "heading",
    "alternateImg",
    "uniqueClasses",
    "subtextclass",
    "subtext",
  ],
  data() {
    return {
      unique: "",
      unique2: "",
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    if (this.$i18n.locale == "fr") {
      this.unique = require("../../assets/svgs/unique.svg");
      this.unique2 = require("../../assets/svgs/lunique.svg");
    } else {
      this.unique = require("../../assets/images/common/section1_logo.png");
      this.unique2 = require("../../assets/images/common/section3_logo.png");
    }
  },
  watch: {
    "$i18n.locale"(value) {
      if (value == "fr") {
        this.unique = require("../../assets/svgs/unique.svg");
      } else {
        this.unique = require("../../assets/images/common/section1_logo.png");
        this.unique2 = require("../../assets/images/common/section3_logo.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
