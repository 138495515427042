<template>
  <div :class="textContentClass">
    <div data-aos="fade-up" data-aos-delay="100">
      <UpperText
        v-if="locale == 'fr'"
        :alternateImg="alternateImg"
        :heading="heading"
        :headingClass="headingClass"
        :subtext="subtext"
        :subtextclass="subtextclass"
      />
      <UpperText
        v-else
        :heading="heading"
        :headingClass="headingClass"
        :subtext="subtext"
        :subtextclass="subtextclass"
      />
      <LowerContent
        :textClass="textClass"
        :text="text"
        @onClick="$emit('onClick')"
        :btnTextClass="btnTextClass"
        :plusIconColor="plusIconColor"
        :btnClass="btnClass"
        :btnText="btnText"
      />
    </div>
  </div>
</template>

<script>
import UpperText from "@/components/general/UpperText.vue";
import LowerContent from "@/components/general/LowerContent.vue";
export default {
  props: [
    "heading",
    "headingClass",
    "textContentbg",
    "textClass",
    "text",
    "btnText",
    "btnClass",
    "plusIconColor",
    "btnTextClass",
    "textContentClass",
    "subtext",
    "subtextclass",
    "alternateImg",
  ],
  components: {
    UpperText,
    LowerContent,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
