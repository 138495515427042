<template>
  <div>
    <h1
      :class="headingClass"
      class="text-primary text-uppercase"
      v-html="heading"
    ></h1>
    <img
      class="unique__img"
      :src="unique"
      :class="uniqueClasses"
      alt="unique image text"
      v-if="locale == 'fr'"
    />
    <img
      v-else
      class="unique__img"
      :src="unique_ar"
      :class="[uniqueClasses, locale]"
      alt="unique image text"
    />
  </div>
</template>

<script>
export default {
  props: ["headingClass", "heading", "uniqueClasses"],
  data() {
    return {
      unique: require("../../../assets/svgs/unique.svg"),
      unique_ar: require("../../../assets/images/common/section1_logo.png"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
