<template>
  <div
    :class="mainTextSectionClass"
    :style="{ backgroundImage: `url(${textContentbg})` }"
    data-aos="bg-pos-left"
    data-aos-delay="100"
    data-aos-anchor-placement="top-center"
  >
    <div :class="textContentClass">
      <div data-aos="fade-up" data-aos-delay="100">
        <UpperText
          :alternateImg="alternateImg"
          :heading="heading"
          :headingClass="headingClass"
        />
        <LowerContent
          :textClass="textClass"
          :text="text"
          @onClick="$emit('onClick')"
          :btnTextClass="btnTextClass"
          :plusIconColor="plusIconColor"
          :btnClass="btnClass"
          :btnText="btnText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UpperText from "@/components/general/UpperText.vue";
import LowerContent from "@/components/general/LowerContent.vue";
export default {
  props: [
    "heading",
    "headingClass",
    "textContentbg",
    "textClass",
    "text",
    "btnText",
    "btnClass",
    "plusIconColor",
    "btnTextClass",
    "textContentClass",
    "mainTextSectionClass",
    "alternateImg",
  ],
  components: {
    UpperText,
    LowerContent,
  },
};
</script>

<style lang="scss" scoped></style>
