<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="123.502"
    height="82.002"
    viewBox="0 0 123.502 82.002"
  >
    <g
      id="Union_2"
      data-name="Union 2"
      transform="translate(-6976.999 -22141)"
      :fill="bg || '#26aed7'"
    >
      <path
        d="M 7081.4990234375 22221.001953125 L 7008.49853515625 22221.001953125 C 7003.958984375 22221.001953125 6999.69091796875 22219.234375 6996.48095703125 22216.0234375 C 6993.2705078125 22212.8125 6991.50244140625 22208.54296875 6991.50244140625 22204 L 6991.50244140625 22195.158203125 L 6991.50244140625 22194.359375 L 6990.9521484375 22193.78125 L 6979.75732421875 22181.998046875 L 6990.95263671875 22170.212890625 L 6991.50244140625 22169.634765625 L 6991.50244140625 22168.8359375 L 6991.50244140625 22160.001953125 C 6991.50244140625 22155.458984375 6993.2705078125 22151.189453125 6996.48095703125 22147.978515625 C 6999.6904296875 22144.767578125 7003.95849609375 22143 7008.49853515625 22143 L 7081.4990234375 22143 C 7086.04150390625 22143 7090.3115234375 22144.767578125 7093.52197265625 22147.978515625 C 7096.73291015625 22151.189453125 7098.5009765625 22155.458984375 7098.5009765625 22160.001953125 L 7098.5009765625 22204 C 7098.5009765625 22208.54296875 7096.73291015625 22212.8125 7093.52197265625 22216.0234375 C 7090.31103515625 22219.234375 7086.04150390625 22221.001953125 7081.4990234375 22221.001953125 Z"
        stroke="none"
      />
      <path
        d="M 7081.4990234375 22219.001953125 C 7085.50732421875 22219.001953125 7089.27490234375 22217.44140625 7092.10791015625 22214.609375 C 7094.94091796875 22211.775390625 7096.5009765625 22208.0078125 7096.5009765625 22204 L 7096.5009765625 22160.001953125 C 7096.5009765625 22155.994140625 7094.94091796875 22152.224609375 7092.10791015625 22149.392578125 C 7089.27490234375 22146.560546875 7085.50732421875 22145 7081.4990234375 22145 L 7008.49853515625 22145 C 7004.49267578125 22145 7000.72705078125 22146.560546875 6997.89501953125 22149.392578125 C 6995.0625 22152.224609375 6993.50244140625 22155.994140625 6993.50244140625 22160.001953125 L 6993.50244140625 22170.43359375 L 6982.51611328125 22181.998046875 L 6993.50244140625 22193.560546875 L 6993.50244140625 22204 C 6993.50244140625 22208.0078125 6995.0625 22211.775390625 6997.89501953125 22214.609375 C 7000.7275390625 22217.44140625 7004.4931640625 22219.001953125 7008.49853515625 22219.001953125 L 7081.4990234375 22219.001953125 M 7081.4990234375 22223.001953125 L 7008.49853515625 22223.001953125 C 6998.0078125 22223.001953125 6989.50244140625 22214.49609375 6989.50244140625 22204 L 6989.50244140625 22195.158203125 L 6976.99853515625 22181.998046875 L 6989.50244140625 22168.8359375 L 6989.50244140625 22160.001953125 C 6989.50244140625 22149.50390625 6998.0078125 22141 7008.49853515625 22141 L 7081.4990234375 22141 C 7091.99560546875 22141 7100.5009765625 22149.50390625 7100.5009765625 22160.001953125 L 7100.5009765625 22204 C 7100.5009765625 22214.49609375 7091.99560546875 22223.001953125 7081.4990234375 22223.001953125 Z"
        stroke="none"
        fill="#f7f7f7"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: ["bg"],
};
</script>

<style lang="scss" scoped></style>
