<template>
  <div
    class="commonsection__videoSection"
    :style="{ backgroundImage: `url(${videoSectionBg})` }"
  >
    <button
      @click="onClick"
      class="btn commonsection__videoSection__playbtn"
      :class="videobtnClass"
      id="videoSectionBtn"
    >
      <img :src="playUrl" alt="play button" />
      <span
        class="d-block commonsection__videoSection__playbtn__text"
        :class="locale"
        >{{ subtext }}</span
      >
    </button>
  </div>
</template>

<script>
export default {
  props: ["subtext", "playUrl", "videoSectionBg", "videobtnClass"],
  data() {
    return {};
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped></style>
