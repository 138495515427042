<template>
  <div
    class="d-flex justify-content-center  h-100 w-100"
    :class="{ slidebg: +indexActive == 1 }"
  >
    <div
      class="d-flex flex-column justify-content-center ml-5 align-items-center section3__slider__verticalLines h-100"
    >
      <div
        class="section3__slider__verticalLines__top"
        :class="{ topActive: +indexActive == 1 }"
      ></div>
      <div class="section3__slider__verticalLines__middle my-2"></div>
      <div
        class="section3__slider__verticalLines__bottom"
        :class="{ bottomActive: +indexActive == 1 }"
      ></div>
    </div>
    <div class="w-100 section3__slider__container">
      <swiper
        @slideChange="onSlideChange"
        ref="mySwiper"
        class="swiper "
        :options="swiperOption"
      >
        <swiper-slide>
          <div class="d-flex flex-column  section3__slider__container__slide1">
            <div
              class="section3__slider__container__slide1 slider1container"
              :class="locale"
            >
              <div class="section3__slider__container__slide1__box">
                <h1
                  class="section3__slider__container__slide1__heading text-center pb-2"
                >
                  Composition de l’eau en
                  <span
                    class="section3__slider__container__slide1__heading__alternateText"
                    >mg / L</span
                  >
                </h1>
                <table class="w-100 ">
                  <tr
                    class="section3__slider__container__slide1__box__content"
                    :class="[...slidedata.class]"
                    v-for="(slidedata, i) in slide1Data"
                    :key="i"
                    :style="{ backgroundColor: slidedata.bg }"
                  >
                    <td
                      class="m-0 px-sm-4 py-2 px-3 section3__slider__container__slide1__box__content__english"
                    >
                      {{ slidedata.englishtext }}
                    </td>
                    <td
                      class="m-0 px-sm-4 py-2 px-3 section3__slider__container__slide1__box__content__number px-3"
                    >
                      {{ slidedata.power }}
                    </td>
                    <td
                      class="m-0 px-sm-4 py-2 px-3 section3__slider__container__slide1__box__content__arabic text-right"
                    >
                      {{ slidedata.arabictext }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="d-flex align-items-center h-100 section3__slider__container__slide2"
          >
            <div class="section3__slider__container__slide2__leftContainer ">
              <div class="text-center">
                <img
                  v-if="locale == 'fr'"
                  class="img-fluid section3__slider__container__slide2__left__img"
                  :src="logo"
                  alt=""
                  srcset=""
                />
                <img
                  v-else
                  class="img-fluid section3__slider__container__slide2__left__img"
                  :src="logo2"
                  alt=""
                  srcset=""
                />
              </div>
              <div
                class="section3__slider__container__slide2__left "
                :class="locale"
              >
                <p v-html="$t('unicite.section2_schema.left')"></p>
              </div>
            </div>
            <div
              class="section3__slider__container__slide2__center"
              :class="locale"
            >
              <div class="mb-2">
                <img
                  class="img-fluid"
                  :src="
                    require('../../../assets/images/home/section3/triangle.png')
                  "
                  alt=""
                />
              </div>
              <div
                class="section3__slider__container__slide2__center__union position-relative"
              >
                <Union />
                <p
                  v-html="$t('unicite.section2_schema.centerbox')"
                  class="section3__slider__container__slide2__center__union__text"
                  :class="locale"
                ></p>
              </div>
              <div
                class="section3__slider__container__slide2__center__union center position-relative"
              >
                <Unionleft />
                <p
                  v-html="$t('unicite.section2_schema.centerbox')"
                  class="section3__slider__container__slide2__center__union__text center"
                  :class="locale"
                ></p>
              </div>
              <div
                class="section3__slider__container__slide2__center__union position-relative"
                :class="locale"
              >
                <Union bg="#9FD6DE" />
                <p
                  v-html="$t('unicite.section2_schema.centerbox')"
                  class="section3__slider__container__slide2__center__union__text"
                  :class="locale"
                ></p>
              </div>
            </div>
            <div
              class="section3__slider__container__slide2__right"
              :class="locale"
            >
              <div
                class="section3__slider__container__slide2__right__top"
                :class="locale"
              >
                <p v-html="$t('unicite.section2_schema.rightTop')"></p>
              </div>
              <div
                class="section3__slider__container__slide2__right__bottom mt-2"
                :class="locale"
              >
                <p v-html="$t('unicite.section2_schema.rightBottom')"></p>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Union from "../../svgs/union.vue";
import Unionleft from "../../svgs/unionleft.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    Union,
    Unionleft,
  },
  data() {
    return {
      indexActive: null,
      logo: require("../../../assets/images/home/initial/logo-red.webp"),
      logo2: require("../../../assets/images/common/logo.png"),
      swiperOption: {
        breakpoints: {
          1200: {
            direction: "vertical",
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      slide1Data: [
        {
          englishtext: "Calcium",
          power: "70",
          arabictext: "كالسيوم",
          bg: "#9FD6DE",
          class: "first",
        },
        {
          englishtext: "Magnésium",
          power: "40",
          arabictext: "مغنزيوم",
          bg: "#D5ECF0",
          class: "",
        },
        {
          englishtext: "Potassium",
          power: "8",
          arabictext: "بوتاسيوم",
          bg: "#0AA4D2",
          class: "",
        },
        {
          englishtext: "Sulfate",
          power: "20",
          arabictext: "سولفات",
          bg: "#5DBFC0",
          class: "",
        },
        {
          englishtext: "Nitrate",
          power: "4",
          arabictext: "نترات",
          bg: "#649FBC",
          class: "",
        },
        {
          englishtext: "Chlorures",
          power: "220",
          arabictext: "كلورور",
          bg: "#9FD6DE",
          class: "",
        },
        {
          englishtext: "Sodium",
          power: "120",
          arabictext: "صوديوم",
          bg: "#D5ECF0",
          class: "",
        },
        {
          englishtext: "Bicarbonate",
          power: "335",
          arabictext: "بيكربونات",
          bg: "#0AA4D2",
          class: "last",
        },
      ],
      slide2: {
        left:
          "Avec une minéralité comprise entre 500 mg/L et 1500 mg/L.<br> Ce sont des eaux adaptées pour tous, à consommer en toute occasion.<br> Sidi Harazem est une eau moyennement minéralisée de par son résidu à sec de 840 mg/L. Elle est de ce fait parfaitement adaptée à une consommation quotidienne,<br> particulièrement à une population exigeante, en recherche d’hydratation optimale et de récupération après les efforts physiques et intellectuels.",
        rightTop:
          "À l’image d’une  empreinte génétique <br> unique, l’eau minérale <br> naturelle Sidi<br> Harazem recèle une <br> composition minérale <br> naturelle exclusive <br> qui lui a été conférée <br> grâceau contact avec <br> la roche, dans les <br> entrailles de la terre.",
        rightBottom:
          " Avec une minéralité inférieure à à 500 mg/L, ces eaux légères <br> peuvent être bues par tous, à tout moment de la journée.<br> Elles ne vont pas forcément répondre à un besoin spécifique,<br> elles peuvent être <br> recommandées par le médecin dans le cadre de certains régimes pauvres en sodium,<br> par exemple.",
      },
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    onSlideChange() {
      let activeSlide = this.$refs.mySwiper.$swiper.activeIndex;
      this.indexActive = activeSlide;
    },
  },
};
</script>

<style lang="scss" scoped></style>
