<template>
  <div class="commonsection position-relative" :class="sectionClass">
    <VideoSection
      :subtext="videosubtext"
      :playUrl="play"
      :videoSectionBg="videoSectionBg"
      @onClick="$emit('onVideobtnClick')"
      :videobtnClass="videobtnClass"
    />

    <TextContent
      :heading="textContentHeading"
      :headingClass="headingClass"
      :textClass="textClass"
      :text="text"
      :btnText="btnText"
      :btnClass="btnClass"
      :plusIconColor="plusIconColor"
      :btnTextClass="btnTextClass"
      :textContentClass="textContentClass"
      @onClick="TextModal = true"
      :textContentbg="textContentbg"
      :mainTextSectionClass="textContentMainClass"
      :alternateImg="alternateImg"
    />

    <TextModalComp
      v-if="locale == 'fr'"
      modalboxClass="section2__textModal"
      :headingtext1="modalContent.heading1"
      :headingtext2="modalContent.heading2"
      :headingtext3="modalContent.heading3"
      :text1="modalContent.text1"
      :text2="modalContent.text2"
      headingClass="modalbox__modalHeading"
      alternateHeadingClass="modalbox__modalHeading__alternate"
      textClass="modalbox__modalText d-block py-1"
      :showModal="TextModal"
      v-bind:value.sync="TextModal"
      :mobileModal="mobileModal"
    />
    <TextModalCompAr
      v-else
      modalboxClass="section2__textModal ar"
      :headingtext1="modalContent.heading1"
      :headingtext2="modalContent.heading2"
      :headingtext3="modalContent.heading3"
      :text1="modalContent.text1"
      :text2="modalContent.text2"
      headingClass="modalbox__modalHeading ar"
      alternateHeadingClass="modalbox__modalHeading__alternate ar"
      textClass="modalbox__modalText d-block py-1"
      :showModal="TextModal"
      v-bind:value.sync="TextModal"
      :mobileModal="mobileModal"
      :uniqueImg="uniqueImgAr"
    />
  </div>
</template>

<script>
import VideoSection from "@/components/common/VideoSection";

import TextContent from "@/components/common/TextSection";
import TextModalComp from "@/components/general/Modal.vue";
import TextModalCompAr from "@/components/general/Modal_ar.vue";

export default {
  props: [
    "sectionClass",
    "mobileModal",
    "videobtnClass",
    "uniqueImgAr",
    "videosubtext",
    "videoSectionBg",
    "modalbg",
    "textContentHeading",
    "headingClass",
    "text",
    "textClass",
    "btnText",
    "btnClass",
    "plusIconColor",
    "btnTextClass",
    "textContentClass",
    "textContentbg",
    "textContentMainClass",
    "alternateImg",
    "modalContent",
  ],
  components: {
    VideoSection,
    TextModalCompAr,

    TextContent,
    TextModalComp,
  },

  data() {
    return {
      // showModal: false,
      TextModal: false,
      play: require("../../assets/images/home/common/play.svg"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
