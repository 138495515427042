<template>
  <svg
    :class="ModalsvgClass"
    id="Component_31_1"
    data-name="Component 31 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="570"
    height="558"
    viewBox="0 0 570 558"
  >
    <ellipse
      id="Ellipse_61"
      data-name="Ellipse 61"
      cx="285"
      cy="279"
      rx="285"
      ry="279"
      :fill="bg || '#0f4c9c'"
      opacity="0.838"
    />
    <ellipse
      id="Ellipse_60"
      data-name="Ellipse 60"
      cx="261"
      cy="253"
      rx="261"
      ry="253"
      transform="translate(24 26)"
      :fill="bg || '#0f4c9c'"
      opacity="0.838"
    />
    <ellipse
      id="Ellipse_62"
      data-name="Ellipse 62"
      cx="204"
      cy="197"
      rx="204"
      ry="197"
      transform="translate(81 82)"
      :fill="bg || '#0f4c9c'"
      opacity="0.838"
    />
  </svg>
</template>

<script>
export default { props: ["bg", "ModalsvgClass"] };
</script>

<style lang="scss" scoped></style>
