<template>
  <section class="section3" id="uniciteSection3">
    <div class="position-relative section3__left">
      <TextContent
        v-if="locale == 'fr'"
        :heading="$t('unicite.Section_2_title')"
        headingClass="section3__heading"
        textClass="section3__text"
        :text="$t('unicite.Section_2_desc_short')"
        :btnText="$t('unicite.button_more')"
        btnClass="section3__btn"
        plusIconColor="red"
        btnTextClass="section3__btn__text"
        textContentClass="section3__textcontent"
        @onClick="TextModal = true"
      />
      <TextContentAr
        v-else
        :heading="$t('unicite.Section_2_title')"
        :headingClass="`section3__heading ${locale}`"
        :textClass="`section3__text  ${locale}`"
        :text="$t('unicite.Section_2_desc_short')"
        :btnText="$t('unicite.button_more')"
        :btnClass="`section3__btn ${locale}`"
        plusIconColor="red"
        btnTextClass="section3__btn__text"
        :textContentClass="`section3__textcontent ${locale}`"
        @onClick="TextModal = true"
        alternateImg="false"
      />

      <TextModalComp
        v-if="locale == 'fr'"
        modalboxClass="section3__textModal"
        :headingtext1="modalContent.heading1"
        :headingtext2="modalContent.heading2"
        :headingtext3="modalContent.heading3"
        :text1="modalContent.text1"
        :text2="modalContent.text2"
        :headingClass="`modalbox__modalHeading ${locale}`"
        :alternateHeadingClass="`modalbox__modalHeading__alternate ${locale}`"
        :textClass="`modalbox__modalText d-block py-1 ${locale}`"
        :showModal="TextModal"
        v-bind:value.sync="TextModal"
        :mobileModal="mobileModal"
      />
      <TextModalCompAR
        v-else
        modalboxBodyClass="section3__modalAR"
        modalboxClass="section3__textModal"
        :headingtext1="modalContent.heading1"
        :headingtext2="modalContent.heading2"
        :headingtext3="modalContent.heading3"
        :text1="modalContent.text1"
        :text2="modalContent.text2"
        :headingClass="`modalbox__modalHeading ${locale} section3__modalAR`"
        :alternateHeadingClass="
          `modalbox__modalHeading__alternate section3__modalAR ${locale}`
        "
        :textClass="
          `modalbox__modalText d-block py-1 section3__modalAR ${locale}`
        "
        :showModal="TextModal"
        v-bind:value.sync="TextModal"
        :mobileModal="mobileModal"
        :uniqueImg="modalContent.uniqueImg"
      />
    </div>

    <div class="section3__right  d-flex  w-100 align-items-center h-100">
      <VerticalSlider />
    </div>
  </section>
</template>

<script>
import TextContent from "@/components/Home/section5/TextContent.vue";
import TextContentAr from "@/components/Home/section3/TextContentAr.vue";
import TextModalComp from "@/components/general/Modal.vue";
import TextModalCompAR from "@/components/general/Modal_ar.vue";
import VerticalSlider from "@/components/Home/section3/VerticalSlider.vue";
export default {
  data() {
    return {
      mobileModal: {},
      unique: require("../../assets/images/home/section4/unique.webp"),
      heading: "Une composition <br> minérale naturelle ",
      TextModal: false,
      modalContent: {},
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.modalContent = {
      heading1: this.$i18n.t("unicite.Section_2_modal.heading1"),
      unique: this.$i18n.t("unicite.Section_2_modal.unique"),
      heading3: "",

      text1: this.$i18n.t("unicite.Section_2_modal.text1"),
      text2: "",
      uniqueImg: require("../../assets/images/common/unique_modal_ar2.png"),
    };
    this.mobileModal = {
      alternatePositionClass: "d-flex  flex-column-reverse",

      unique: require("../../assets/images/home/section4/unique.webp"),
      uniqueAr: require("../../assets/images/common/unique_modal_ar2.png"),
      uniqueClass: "section3__textModal__mobile__unique ",
      mobileModalClass: `section3__textModal__mobile ${this.$i18n.locale}`,
      mobileModalHeadingClass: `section3__textModal__mobile__heading ${this.$i18n.locale}`,
      mobileModalSubtextClass: `section3__textModal__mobile__subtext ${this.$i18n.locale}`,
      mobileModalTextClass: `section3__textModal__mobile__text ${this.$i18n.locale}`,
      cross: require("../../assets/images/home/common/cross.webp"),

      heading: this.$i18n.t("unicite.Section_2_mobile_modal.heading"),
      text: this.$i18n.t("unicite.Section_2_mobile_modal.text"),
    };
  },
  watch: {
    "$i18n.locale"(value) {
      this.modalContent = {
        heading1: this.$i18n.t("unicite.Section_2_modal.heading1"),
        unique: this.$i18n.t("unicite.Section_2_modal.unique"),
        heading3: "",

        text1: this.$i18n.t("unicite.Section_2_modal.text1"),
        text2: "",
        uniqueImg: require("../../assets/images/common/unique_modal_ar.png"),
      };
      this.mobileModal = {
        alternatePositionClass: "d-flex  flex-column-reverse",

        unique: require("../../assets/images/home/section4/unique.webp"),
        uniqueAr: require("../../assets/images/common/unique_modal_ar.png"),
        uniqueClass: "section3__textModal__mobile__unique ",
        mobileModalClass: `section3__textModal__mobile ${this.$i18n.locale}`,
        mobileModalHeadingClass: `section3__textModal__mobile__heading ${this.$i18n.locale}`,
        mobileModalSubtextClass: `section3__textModal__mobile__subtext ${this.$i18n.locale}`,
        mobileModalTextClass: `section3__textModal__mobile__text ${this.$i18n.locale}`,
        cross: require("../../assets/images/home/common/cross.webp"),

        heading: this.$i18n.t("unicite.Section_2_mobile_modal.heading"),
        text: this.$i18n.t("unicite.Section_2_mobile_modal.text"),
      };
    },
  },
  components: {
    TextContent,
    TextModalComp,
    VerticalSlider,
    TextModalCompAR,
    TextContentAr,
  },
};
</script>

<style lang="scss" scoped></style>
