<template>
  <div class="modalbox__body__logo">
    <svg
      id="Component_5_12"
      data-name="Component 5 – 12"
      xmlns="http://www.w3.org/2000/svg"
      width="38.52"
      height="39.106"
      viewBox="0 0 38.52 39.106"
    >
      <g id="Group_925" data-name="Group 925">
        <path
          id="Path_3219"
          data-name="Path 3219"
          d="M54.191,96.7a1.487,1.487,0,0,0-1.612,1.545c0,1.791,2.35,1.9,2.35,3.38a.867.867,0,0,1-.985.918,1.376,1.376,0,0,1-1.186-.716h-.067l-.291.4a1.841,1.841,0,0,0,1.567.783,1.628,1.628,0,0,0,1.746-1.724c0-1.925-2.373-2.1-2.373-3.358,0-.515.358-.761.851-.761a1.116,1.116,0,0,1,.963.537h.09l.269-.4a1.569,1.569,0,0,0-1.321-.6"
          transform="translate(-40.652 -75.054)"
          fill="#fff"
        />
        <path
          id="Path_3220"
          data-name="Path 3220"
          d="M108.135,97.1h-.918v2.686h-1.5V97.1H104.8v6.089h.918v-2.932h1.5v2.932h.918Z"
          transform="translate(-81.322 -75.364)"
          fill="#fff"
        />
        <path
          id="Path_3221"
          data-name="Path 3221"
          d="M10.016,119.9a.584.584,0,0,0-.246.067.744.744,0,0,0-.246.179c-.09.09-.2.224-.336.381-.2.224-.4.448-.627.672s-.492.448-.761.672a1.415,1.415,0,0,0,.47.112.715.715,0,0,0,.313-.067,2.184,2.184,0,0,0,.47-.358c.157-.157.336-.358.537-.582.09-.112.179-.2.224-.269a.852.852,0,0,1,.112-.134c.022-.022.045-.067.067-.09h.022c.134,0,.4.515.918,1.455.134.246.246.448.358.649s.224.358.313.537c.157-.045.336-.09.492-.134-.045-.157-.067-.269-.09-.381s-.022-.2-.045-.291a8.145,8.145,0,0,1,.112-.918,1.709,1.709,0,0,0,.022-.828,1.376,1.376,0,0,0-.537-.358,2.213,2.213,0,0,0-.515-.224h-.022c-.067.022-.112.067-.179.09.224.246.47.47.694.672-.045.313-.09.627-.112.94a.346.346,0,0,0-.067-.134q-.134-.235-.47-.739a5.08,5.08,0,0,0-.515-.761.528.528,0,0,0-.358-.157"
          transform="translate(-6.036 -93.061)"
          fill="#fff"
        />
        <path
          id="Path_3222"
          data-name="Path 3222"
          d="M26.04,130.3a4.588,4.588,0,0,1-.537.112c.067.291.157.582.246.873l.537-.134c-.067-.269-.157-.56-.246-.851m-4.119,1.41a2.716,2.716,0,0,0,.492,2.037,2.327,2.327,0,0,0,1.343.783,3.059,3.059,0,0,0,.425.045,1.245,1.245,0,0,0,1.052-.515,2.126,2.126,0,0,0,.224-.381,2.618,2.618,0,0,0,.112-.448,3.153,3.153,0,0,0,.022-.448,1.309,1.309,0,0,0-.09-.425c-.2.067-.4.134-.627.2.179.6.2,1.03.022,1.254-.022.045-.09.067-.134.09a2.208,2.208,0,0,1-.537.067,2.155,2.155,0,0,1-1.679-.918,3.527,3.527,0,0,1-.336-.537,4.541,4.541,0,0,1-.246-.582c-.022-.09-.022-.157-.045-.224"
          transform="translate(-16.979 -101.132)"
          fill="#fff"
        />
        <path
          id="Path_3223"
          data-name="Path 3223"
          d="M44.11,134.9c-.224.09-.448.179-.672.246-.739,1.186-1.5,2.35-2.238,3.537.291-.067.56-.157.851-.224.672-1.209,1.365-2.373,2.059-3.559"
          transform="translate(-31.959 -104.703)"
          fill="#fff"
        />
        <path
          id="Path_3224"
          data-name="Path 3224"
          d="M46.037,153.4c-.157.157-.336.313-.515.47.336.515.492.918.4,1.209-.022.045-.045.112-.112.134a1.972,1.972,0,0,1-1.209.448,2.342,2.342,0,0,1-1.1-.313,1.052,1.052,0,0,1-.269-.2c-.09-.067-.2-.179-.313-.269a3.449,3.449,0,0,1-.291-.313,1.434,1.434,0,0,1-.134-.224,2.83,2.83,0,0,0,.157.56c.067.157.112.291.157.4a2.034,2.034,0,0,0,.761.806,1.917,1.917,0,0,0,.985.269,2.023,2.023,0,0,0,.627-.09,1.415,1.415,0,0,0,1.03-.9.022.022,0,0,1-.022-.022c.067-.2.134-.425.2-.627h-.022a2.354,2.354,0,0,0-.336-1.343m.448,1.343c.134.045.246.09.381.134-.112-.045-.246-.09-.381-.134m.515.179h0m-.537.492c.067.022.157.067.224.09a.671.671,0,0,1-.224-.09"
          transform="translate(-32.968 -119.062)"
          fill="#fff"
        />
        <path
          id="Path_3225"
          data-name="Path 3225"
          d="M61.171,155.1a.737.737,0,0,0-.425.134,3.676,3.676,0,0,0-.515.47c.2,0,.381-.022.537-.022a1.117,1.117,0,0,1,.425.045,2.487,2.487,0,0,1,.716.381,4.188,4.188,0,0,1,.515.515c.067.09.134.157.179.224a.346.346,0,0,1,.067.134,17.623,17.623,0,0,1-2.843-.716h-.022c-.045-.022-.067-.022-.112-.045-.134-.045-.246-.09-.381-.134-.045-.022-.067-.022-.112-.045h0c-.067.2-.134.425-.2.627a.022.022,0,0,1,.022.022c.067.022.157.067.224.09s.157.067.224.09a17.771,17.771,0,0,0,4.813,1.03V157.2a4.258,4.258,0,0,1-1.522-1.03,2.854,2.854,0,0,0-1.343-1.007.32.32,0,0,0-.246-.067"
          transform="translate(-45.775 -120.381)"
          fill="#fff"
        />
        <path
          id="Path_3226"
          data-name="Path 3226"
          d="M97.547,146.8a.692.692,0,0,0-.179.045,2.331,2.331,0,0,0-.985,1.142,2.129,2.129,0,0,0-.336,1.589.41.41,0,0,0,.425.336.525.525,0,0,0,.246-.045c.179-.045.649-.09,1.365-.157a.677.677,0,0,1-.2.4,2.847,2.847,0,0,1-.649.492,4.441,4.441,0,0,1-1.3.537,4.1,4.1,0,0,1-.47.045,1,1,0,0,1-1.119-.873,3.005,3.005,0,0,1-.022-.47c.022-.179.045-.4.067-.649a3.313,3.313,0,0,1,.269-1.074,2.532,2.532,0,0,0-.56,1.186,3.563,3.563,0,0,0-.157,1.388,2.128,2.128,0,0,0,.2.6,1.468,1.468,0,0,0,.4.425,1.507,1.507,0,0,0,.582.224,1.692,1.692,0,0,0,.336.022,2.169,2.169,0,0,0,.381-.022,4.9,4.9,0,0,0,1.813-.828c.851-.582,1.164-1.052,1.052-1.343-.067-.2-.157-.4-.224-.6-.022-.067-.09-.112-.2-.157a.766.766,0,0,0-.246-.022H97.86c-.045,0-.157.022-.313.045s-.381.045-.672.09c-.112,0-.2.022-.269.022-.179,0-.269-.022-.291-.067-.045-.157.112-.425.448-.783a2.217,2.217,0,0,1,.716-.627h.067a.837.837,0,0,1,.336.112,1.377,1.377,0,0,0-.067-.783c-.09-.157-.157-.2-.269-.2m-.224,5.059-.2.6c.336.09.694.179,1.052.246.067-.224.134-.425.179-.649-.358-.045-.716-.134-1.03-.2m-1.209.291c-.067.2-.157.4-.224.6.336.112.672.2,1.007.291.067-.2.157-.381.224-.582-.358-.112-.694-.2-1.007-.313"
          transform="translate(-72.883 -113.939)"
          fill="#fff"
        />
        <path
          id="Path_3227"
          data-name="Path 3227"
          d="M119.271,143.2c.067.313.112.649.157.963h0a2.7,2.7,0,0,1,.716.492,5.475,5.475,0,0,1,.739.649c.045.09-.067.246-.381.515a3.2,3.2,0,0,1-.336.246c-.112.067-.2.134-.291.179a1.444,1.444,0,0,1-.6.2h-.022q-.067,0-.2-.269a2.44,2.44,0,0,0-.134-.246h-.022a.125.125,0,0,0-.022.134,1.123,1.123,0,0,0,.045.269,1.448,1.448,0,0,0,.09.291,2.322,2.322,0,0,0,.09.224.616.616,0,0,0,.448.381h.022a1.546,1.546,0,0,0,.6-.2,5.971,5.971,0,0,0,.828-.56c.425-.336.6-.537.582-.627.067-.045.112-.067.179-.112-.134-.2-.291-.4-.448-.6.134-.09.291-.2.425-.291-.134.112-.291.2-.425.313a15.362,15.362,0,0,0-2.037-1.947m3.067,2.082c-.022.022-.067.045-.09.067a.309.309,0,0,1,.09-.067m-.112.067h0"
          transform="translate(-92.234 -111.145)"
          fill="#fff"
        />
        <path
          id="Path_3228"
          data-name="Path 3228"
          d="M130.058,140c.112.291.2.56.291.851.022,0,.022-.022.067-.022a.243.243,0,0,1,.112.022c.09.022.179.067.269.09.09.045.2.09.291.134a.409.409,0,0,1,.179.134c.045.045.045.09.022.112-.381.336-.761.649-1.164.94a4.8,4.8,0,0,1-.425.291c.157.2.291.4.448.6h0c.157-.112.336-.224.492-.336h0c.022-.022.067-.045.09-.067a17.155,17.155,0,0,0,2.037-1.791h0l-.537.537-.537-.56c.022,0,.067-.045.157-.134.134-.134.246-.269.358-.381h0c-.157.157-.313.336-.492.492a3.4,3.4,0,0,0-.806-.582,2.776,2.776,0,0,0-.851-.336m3.2,2.709c-.336.09-.694.179-1.03.246l.067.672a9.031,9.031,0,0,1-1.052.134c.022.224.022.448.045.672.358-.045.694-.09,1.052-.157,0-.224-.022-.448-.045-.672l1.074-.2c-.045-.2-.067-.448-.112-.694"
          transform="translate(-100.649 -108.661)"
          fill="#fff"
        />
        <path
          id="Path_3229"
          data-name="Path 3229"
          d="M141.107,116.9c.09.269.179.56.246.828a.381.381,0,0,1,.157-.022,1.082,1.082,0,0,1,.47.134c.179.09.179.358-.022.761-.179.358-.336.537-.425.537h-.045l-.022-.022a2.092,2.092,0,0,0-.224-.224c-.134-.09-.269-.2-.381-.291-.134-.09-.246-.179-.381-.269.09.269.157.56.224.828a.942.942,0,0,1,.313.179c.157.112.246.179.246.224a1.9,1.9,0,0,1-.4.828l-.134.2a2.839,2.839,0,0,1-.649.694c-.045.022-.067.045-.09.045h0a.612.612,0,0,1-.09-.112c-.045-.045-.09-.112-.157-.179a1.23,1.23,0,0,1-.157-.2.38.38,0,0,0-.134-.134c-.09-.067-.157-.134-.224-.179s-.112-.09-.157-.112l.134.806a.994.994,0,0,1,.246.179.686.686,0,0,1,.224.269c.022.09-.157.381-.56.828-.112.112-.224.246-.358.381-.09.09-.134.134-.157.134l.537.56.537-.537.134-.134c.358-.381.515-.694.492-.9a3.613,3.613,0,0,0,1.1-1.03,2.672,2.672,0,0,0,.425-1.186,3.253,3.253,0,0,0,1.1-1.388,1.453,1.453,0,0,0,.09-.739,5.03,5.03,0,0,0-.9-.515,4.426,4.426,0,0,0-1.007-.246"
          transform="translate(-107.556 -90.732)"
          fill="#fff"
        />
        <path
          id="Path_3230"
          data-name="Path 3230"
          d="M2.153,88.7H2.041c-.47.045-.694.381-.828,1.164-.09.56-.179.739-.381.761H.787c-.224,0-.358-.2-.4-.56a1.515,1.515,0,0,1,.157-.851l-.425-.224a1.669,1.669,0,0,0-.179,1.142c.09.649.425,1.03.9,1.03h.09c.47-.045.649-.4.783-1.142.112-.582.2-.761.425-.783h.045c.224,0,.381.179.425.582a1.581,1.581,0,0,1-.2.918l.4.224a1.715,1.715,0,0,0,.246-1.209C3,89.013,2.623,88.7,2.153,88.7"
          transform="translate(0.082 -68.845)"
          fill="#fff"
        />
        <path
          id="Path_3231"
          data-name="Path 3231"
          d="M1.957,67.7A1.488,1.488,0,0,0,.435,69.065a1.526,1.526,0,0,0,1.343,1.791,1,1,0,0,0,.246.022,1.488,1.488,0,0,0,1.522-1.365,1.514,1.514,0,0,0-1.321-1.791c-.09,0-.179-.022-.269-.022m.067,2.664a.493.493,0,0,1-.179-.022,1.041,1.041,0,0,1-.94-1.209,1.058,1.058,0,0,1,1.052-.918.493.493,0,0,1,.179.022,1.025,1.025,0,0,1,.918,1.209.991.991,0,0,1-1.03.918"
          transform="translate(-0.305 -52.545)"
          fill="#fff"
        />
        <path
          id="Path_3232"
          data-name="Path 3232"
          d="M5.474,46.8l-.2.448,1.589.694a.78.78,0,0,1,.47,1.1.756.756,0,0,1-.716.492,1.038,1.038,0,0,1-.425-.09L4.6,48.747l-.2.448,1.589.694a1.478,1.478,0,0,0,.6.134,1.205,1.205,0,0,0,1.142-.806,1.23,1.23,0,0,0-.716-1.724Z"
          transform="translate(-3.397 -36.324)"
          fill="#fff"
        />
        <path
          id="Path_3233"
          data-name="Path 3233"
          d="M16.546,30.4a1.258,1.258,0,0,0-.94.56l-.806,1.03,2.373,1.858.313-.4-.761-.582.537-.672h1.209l.336-.425-.045-.045H17.553l-.022-.022a.944.944,0,0,0-.358-1.074,1.074,1.074,0,0,0-.627-.224m-.157,2.194-.94-.716.492-.627a.728.728,0,0,1,.56-.336.6.6,0,0,1,.358.134c.313.246.291.582.045.918Z"
          transform="translate(-11.469 -23.595)"
          fill="#fff"
        />
        <path
          id="Path_3234"
          data-name="Path 3234"
          d="M32.284,16a1.631,1.631,0,0,0-.963.358,1.519,1.519,0,0,0-.269,2.216,1.55,1.55,0,0,0,1.209.627,1.68,1.68,0,0,0,1.007-.358,1.527,1.527,0,0,0,.6-.963l-.47-.112a1.109,1.109,0,0,1-.425.694,1.053,1.053,0,0,1-1.522-.179,1.08,1.08,0,0,1,.157-1.545,1.26,1.26,0,0,1,.739-.246H32.4V16h-.112"
          transform="translate(-23.804 -12.418)"
          fill="#fff"
        />
        <path
          id="Path_3235"
          data-name="Path 3235"
          d="M47.682,5.7,45.6,6.663l1.276,2.731,2.059-.94-.179-.4-1.612.739-.358-.761L48.152,7.4,48,7.021l-1.388.627-.358-.783L47.861,6.1Z"
          transform="translate(-35.374 -4.424)"
          fill="#fff"
        />
        <path
          id="Path_3236"
          data-name="Path 3236"
          d="M77.607,0,75.1.157l.022.425L76.13.537l.157,2.552.492-.022L76.622.492,77.629.448Z"
          transform="translate(-58.271)"
          fill="#fff"
        />
        <path
          id="Path_3237"
          data-name="Path 3237"
          d="M93.637.5,93.1,3.455l.492.09.224-1.231,1.589.269-.224,1.254.492.09L96.211.97l-.492-.09-.224,1.3-1.612-.291L94.13.59Z"
          transform="translate(-72.241 -0.388)"
          fill="#fff"
        />
        <path
          id="Path_3238"
          data-name="Path 3238"
          d="M113.054,6,111.8,8.731l2.059.94.2-.4-1.634-.739.358-.761,1.365.627.179-.381-1.365-.627.358-.783,1.612.739.179-.4Z"
          transform="translate(-86.755 -4.657)"
          fill="#fff"
        />
        <path
          id="Path_3239"
          data-name="Path 3239"
          d="M128.558,15.3,126.7,17.65l.381.313.582-.739.672.537v1.209l.425.336.045-.045V18.053l.022-.022a1.512,1.512,0,0,0,.313.045.935.935,0,0,0,.761-.4c.4-.515.269-1.074-.336-1.545Zm.515,2.306a.725.725,0,0,1-.47-.2l-.649-.515.739-.918.627.492c.336.269.448.6.2.918a.712.712,0,0,1-.448.224"
          transform="translate(-98.32 -11.875)"
          fill="#fff"
        />
        <path
          id="Path_3240"
          data-name="Path 3240"
          d="M142.718,29.7,140.3,31.491l.313.381,1.567-1.164-.224,1.365.067.09,1.365.179L141.8,33.505l.313.4,2.418-1.791-.09-.112-1.947-.269.313-1.925Z"
          transform="translate(-108.876 -23.052)"
          fill="#fff"
        />
        <path
          id="Path_3241"
          data-name="Path 3241"
          d="M151.1,53.5l.2.515.515.045.56,1.455-.336.381.2.537,2.261-2.507-.045-.09Zm1.567,1.679-.425-1.1h.067l1.3.09v.022l-.9.918Z"
          transform="translate(-117.258 -41.524)"
          fill="#fff"
        />
        <path
          id="Path_3242"
          data-name="Path 3242"
          d="M160.2,70.1l-3,.4.313,2.149.425-.045-.224-1.656,2.552-.358Z"
          transform="translate(-121.993 -54.408)"
          fill="#fff"
        />
        <path
          id="Path_3243"
          data-name="Path 3243"
          d="M157.979,87.6l-.179,2.261.425.045.157-1.791.828.09-.112,1.5.4.045.134-1.522.873.067-.157,1.791.448.022.179-2.261Z"
          transform="translate(-122.458 -67.991)"
          fill="#fff"
        />
        <path
          id="Path_3244"
          data-name="Path 3244"
          d="M40.247,76.4H39.016a.753.753,0,0,0,.112,1.5h.112l.09-.022a13.632,13.632,0,0,1,1.9-.157,5.937,5.937,0,0,1,1.254.112,5.741,5.741,0,0,1,2.843,1.321,9.125,9.125,0,0,1,2.82,5.887,10.917,10.917,0,0,0-.56-3.38,8.059,8.059,0,0,0-1.768-3.044,6.576,6.576,0,0,0-3.089-1.88,8.154,8.154,0,0,0-1.791-.336h-.694"
          transform="translate(-29.753 -59.298)"
          fill="#fff"
        />
        <path
          id="Path_3245"
          data-name="Path 3245"
          d="M47.845,54.3a.761.761,0,0,0-.739.582.739.739,0,0,0,.537.918h.022a10.111,10.111,0,0,1,4.387,2.35,11.616,11.616,0,0,1,2.977,4.231,12.1,12.1,0,0,0-2.44-4.768,11.129,11.129,0,0,0-4.477-3.268c-.022-.022-.067-.022-.112-.045h-.157"
          transform="translate(-36.522 -42.145)"
          fill="#fff"
        />
        <path
          id="Path_3246"
          data-name="Path 3246"
          d="M63.37,41.3a.726.726,0,0,0-.649.381.77.77,0,0,0,.269,1.052l.045.022a8.041,8.041,0,0,1,1.746,1.3,8.27,8.27,0,0,1,1.365,1.768,10.5,10.5,0,0,1,1.365,4.387,10.639,10.639,0,0,0-.672-4.7,8.57,8.57,0,0,0-1.186-2.171,9.044,9.044,0,0,0-1.768-1.813c-.022-.022-.067-.045-.09-.067a.594.594,0,0,0-.425-.157"
          transform="translate(-48.577 -32.055)"
          fill="#fff"
        />
        <path
          id="Path_3247"
          data-name="Path 3247"
          d="M98.6,76.4c-.224,0-.47,0-.694.022a8.155,8.155,0,0,0-1.791.336,6.4,6.4,0,0,0-3.089,1.88,8.364,8.364,0,0,0-1.768,3.044,10.916,10.916,0,0,0-.56,3.38,9.344,9.344,0,0,1,2.821-5.887,5.653,5.653,0,0,1,2.843-1.321,5.807,5.807,0,0,1,1.254-.112,13.631,13.631,0,0,1,1.9.157l.09.022h.112a.761.761,0,0,0,0-1.522H98.6"
          transform="translate(-70.379 -59.298)"
          fill="#fff"
        />
        <path
          id="Path_3248"
          data-name="Path 3248"
          d="M97.308,54.3a.689.689,0,0,0-.291.067,11.284,11.284,0,0,0-4.477,3.268A12.1,12.1,0,0,0,90.1,62.4a11.889,11.889,0,0,1,2.977-4.231,10.111,10.111,0,0,1,4.387-2.35h.022c.022,0,.067-.022.09-.022a.739.739,0,0,0,.4-.985.686.686,0,0,0-.672-.515"
          transform="translate(-69.913 -42.145)"
          fill="#fff"
        />
        <path
          id="Path_3249"
          data-name="Path 3249"
          d="M92.488,41.3a.832.832,0,0,0-.492.179,9.692,9.692,0,0,0-1.768,1.813,9.329,9.329,0,0,0-1.186,2.171,10.639,10.639,0,0,0-.672,4.7,10.5,10.5,0,0,1,1.365-4.387A8.111,8.111,0,0,1,91.1,44.009a8.232,8.232,0,0,1,1.746-1.3l.045-.022c.022-.022.067-.045.09-.067a.773.773,0,0,0,.09-1.074.853.853,0,0,0-.582-.246"
          transform="translate(-68.54 -32.055)"
          fill="#fff"
        />
        <path
          id="Path_3250"
          data-name="Path 3250"
          d="M84.023,25.3h-.067a.752.752,0,0,0-.672.828l.761,7.544.739-7.521v-.157a.773.773,0,0,0-.761-.694"
          transform="translate(-64.619 -19.637)"
          fill="#fff"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
