<template>
  <div
    class="cursor-pointer general__btn"
    @click="showmodalbox"
    :class="[btnClass, locale]"
  >
    <img :src="require('../../assets/images/common/redBtn_ar.png')" alt="" />
  </div>
</template>

<script>
export default {
  props: ["btnTextClass", "btnText", "btnClass", "plusIconColor"],
  data() {
    return {
      btnImg: require("../../assets/images/common/redBtn_ar.png"),
    };
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    showmodalbox() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped></style>
