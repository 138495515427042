<script>
import { BModal } from "bootstrap-vue";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
  components: {
    BModal,
    videoPlayer
  },
  data() {
    return {
      showModal: false,
      playerOptions: {
        // videojs options
        muted: true,
        language: "fr",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        autoplay: true,
        loop: true,
        sources: [
          {
            type: "video/mp4",
            src: "/VF.mp4"
          }
        ],
        poster: "/static/images/author.jpg"
      }
    };
  },
  mounted() {
    // check hash and if it's contains promo then show modal
    if (this.$route.hash === "#promo") {
      if (this.$i18n.locale == "ar") {
        this.$i18n.locale = "fr";
        localStorage.setItem("lang", this.$i18n.locale);
        location.reload();
      }
      this.$bvModal.show("modal");
    }
    if(this.$route.hash === "#promo-ar")
    {
      if (this.$i18n.locale == "fr") {
        this.$i18n.locale = "ar";
        localStorage.setItem("lang", this.$i18n.locale);
        location.reload();
      }
      this.playerOptions.language = "ar";
      this.playerOptions.sources = [
        {
          type: "video/mp4",
          src: "/VA.mp4"
        }
      ];
      this.$bvModal.show("modal");
    }
  },
  methods: {}
};
</script>

<template>
  <div style="position: fixed; z-index: 954565465;">
    <b-modal id="modal" centered hide-footer hide-header>
      <div class="containerVideo">
        <video-player
          class="video"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
        />
      </div>
      <!-- close button  -->
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('modal')"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </b-modal>
  </div>
</template>

<style>
#modal___BV_modal_outer_ {
  z-index: 99999999 !important;
}

.modal {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.modal-dialog {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  margin: 0 auto !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.modal-content {
  margin: 0 auto !important;
  max-width: 100vw !important;
  max-height: calc(100vh - 20%) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 90% !important;
  height: 90% !important;
  background-color: rgba(0, 0, 0, 1) !important;
  border: 0px solid transparent !important;
}

.modal-body {
  width: 100% !important;
  height: 100% !important;
}

.containerVideo {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  border: 0;
}
.video-js {
  width: 100% !important;
  height: 100% !important;
}

.video-js .vjs-big-play-button {
  display: none !important;
}
.close {
  position: absolute;
  right: -25px;
  top: -0px;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #000000;
  opacity: 1;
  cursor: pointer;
  background: white;
  border: 0;
  outline: 0;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
