<template>
  <div>
    <!-- desktop Modal -->
    <div
      v-click-outside="onClickOutside"
      class="modalbox"
      :class="[{ show: showModal }, modalboxClass]"
    >
      <div class="modalbox__body">
        <ModalBg :ModalsvgClass="ModalsvgClass" />
        <button
          class="modalbox__cross btn ar"
          :class="modalcrossClass"
          @click="$emit('update:value', false)"
        >
          <img :src="cross" alt="" />
        </button>
        <div class="modalbox__body__content ar" :class="modalboxBodyClass">
          <div v-if="desktopimage">
            <img class="img-fluid" :src="desktopimage" alt="" />
          </div>
          <h1 :class="headingClass" class="text-center text-white">
            <span v-if="headingtext1">{{ headingtext1 }}</span>
            <span v-if="headingtext2" :class="alternateHeadingClass">{{
              headingtext2
            }}</span>
            <span v-if="headingtext3" v-html="headingtext3"></span>
          </h1>
          <div v-if="uniqueImg" class="text-center my-2">
            <img class="img-fluid" :src="uniqueImg" alt="" />
          </div>
          <p
            v-html="text1"
            :class="textClass"
            class="text-center"
            v-if="text1"
          ></p>
          <p
            v-html="text2"
            :class="textClass"
            class="text-center"
            v-if="text2"
          ></p>
        </div>
        <Logo />
      </div>
    </div>
    <!-- mobile modal-->

    <div>
      <div :class="[{ show: showModal }, mobileModal.mobileModalClass]">
        <div class="position-relative">
          <button
            class="btn textModal__mobile__cross mr-3"
            @click="$emit('update:value', false)"
          >
            <img :src="mobileModal.cross" alt="" />
          </button>

          <div class="text-center" :class="mobileModal.alternatePositionClass">
            <img
              class="img-fluid"
              :class="mobileModal.uniqueClass"
              v-if="mobileModal.unique && locale == 'fr'"
              :src="mobileModal.unique"
              alt=""
            />
            <img
              v-else
              class="img-fluid"
              :class="mobileModal.uniqueClass"
              :src="mobileModal.uniqueAr"
              alt=""
            />

            <h1
              class="text-center text-uppercase"
              :class="mobileModal.mobileModalHeadingClass"
              v-html="mobileModal.heading"
            ></h1>
          </div>
          <p
            class="text-center"
            v-if="mobileModal.subtext"
            :class="mobileModal.mobileModalSubtextClass"
            v-html="mobileModal.subtext"
          ></p>
          <p
            class="text-center"
            :class="mobileModal.mobileModalTextClass"
            v-html="mobileModal.text"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/svgs/Logo.vue";
import ModalBg from "@/components/svgs/ModalBg.vue";
import vClickOutside from "v-click-outside";
export default {
  props: [
    "modalcrossClass",
    "ModalsvgClass",
    "mobileModal",
    "desktopimage",
    "headingtext1",
    "headingtext2",
    "headingtext3",
    "uniqueImg",
    "alternateHeadingClass",
    "text1",
    "text2",
    "headingClass",
    "textClass",
    "showModal",
    "modalboxClass",
    "modalboxBodyClass",
  ],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside(e) {
      let section2btn = document.querySelector(
        ".section2__textcontent__button"
      );
      let section3btn = document.querySelector(".section3__btn");
      let section5btn = document.querySelector(".section5__btn");

      if (
        !section2btn.contains(e.target) &&
        this.showModal &&
        e.target !== section2btn &&
        !section3btn.contains(e.target) &&
        this.showModal &&
        e.target !== section3btn &&
        !section5btn.contains(e.target) &&
        this.showModal &&
        e.target !== section5btn
      ) {
        this.$emit("update:value", false);
      }
    },
  },
  data() {
    return {
      cross: require("../../assets/images/home/common/cross.webp"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },

  components: {
    Logo,
    ModalBg,
  },
};
</script>

<style lang="scss" scoped></style>
