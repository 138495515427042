<template>
  <div
    class="cursor-pointer general__btn"
    @click="showmodalbox"
    :class="[btnClass, locale]"
  >
    <img
      class="img-fluid general__btn__img"
      v-if="locale == 'fr'"
      :src="btnimgsrc || btnImg"
      alt=""
    />
    <img
      class="img-fluid general__btn__img"
      v-if="locale == 'ar'"
      :src="btnImg2"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: ["btnTextClass", "btnText", "btnClass", "plusIconColor", "btnimgsrc"],
  data() {
    return {
      btnImg: require("../../assets/images/home/common/button.svg"),
      btnImg2: require("../../assets/images/common/button_ar.png"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    showmodalbox() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped></style>
